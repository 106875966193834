export const crearNuevosPreciosCliente = (preciosCliente) => {
  const nuevosPreciosCliente = preciosCliente.map((pc) => {
    const precioClienteId = pc.id;
    const nuevoPrecioCliente = pc.PRECIO;

    return { precioClienteId, nuevoPrecioCliente };
  });

  return nuevosPreciosCliente;
};

export const truncateNombre = (clienteNombre) => {
  if (clienteNombre.length > 10) {
    return clienteNombre.substring(0, 10) + "...";
  }
  return clienteNombre;
};

export const obtenerValoresFiltroClientes = () => {
  const buscar = JSON.parse(localStorage.getItem("clienteBuscar") || '""');
  const filtrarPor = JSON.parse(
    localStorage.getItem("clienteFiltrarPor") || '"nombre"'
  );
  const ordenarPor = JSON.parse(
    localStorage.getItem("clienteOrdenarPor") || '"defecto"'
  );

  return {
    buscar,
    filtrarPor,
    ordenarPor,
  };
};

export const guardarFiltrosCliente = (buscar, filtrarPor, ordenarPor) => {
  localStorage.setItem("clienteBuscar", JSON.stringify(buscar));
  localStorage.setItem("clienteFiltrarPor", JSON.stringify(filtrarPor));
  localStorage.setItem("clienteOrdenarPor", JSON.stringify(ordenarPor));
};
