import { useState } from "react";
import { obtenerValoresFiltroClientes } from "../utilis/clientes";

export const useFiltrosClientes = () => {
  // Estado para los filtros de las lista de clientes
  const [filtrosClientes, setFiltrosClientes] = useState(
    obtenerValoresFiltroClientes
  );

  const { buscar, filtrarPor, ordenarPor } = filtrosClientes;

  // Funcion para modificar el valor con keys dinamicas
  const modificarFiltro = (e) => 
    setFiltrosClientes(prevFiltrosClientes => ({
      ...prevFiltrosClientes, [e.target.name]: e.target.value
    }));

  // Generar el url
  const url = `/clientes?clientefiltrarpor=${filtrarPor}&clientebuscar=${buscar}&clienteordenarpor=${ordenarPor}`;

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    url,
    modificarFiltro
  };
};
