import { Col, Container, Form, Row } from "react-bootstrap";
import {
  StyledBoton,
  StyledBotonContainer,
  StyledFormControlContainer,
  StyledFormGroup,
} from "./styles/FiltroListaVentas.styles";
import { useNavigate } from "react-router-dom";
import { guardarFiltrosVenta } from "../../lib/utilis/venta";
import { useFiltrosVentas } from "../../lib/hooks/useFiltrosVentas";
import { useRef } from "react";

const FiltroListaVentas = () => {
  // Funcion para navegar de regreso a la pagina de clientes con el url modificado
  const navigate = useNavigate();

  //Obtener referencia del boton
  const actualizarRef = useRef(null);

  //  Customo hook para los filtros del formulario de filtro ventas
  const {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro,
  } = useFiltrosVentas();

  const manejarSubmit = (e) => {
    e.preventDefault();
    // Guardamos los filtros para recuperarlos despues de renderizar el componente
    guardarFiltrosVenta(
      buscar,
      filtrarPor,
      ordenarPor,
      fechaInicio,
      fechaFinal
    );
    // Volver a renderizar la pagina de clientes con el url actualizado
    navigate(url);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={manejarSubmit}>
            {/* Buscar por campo seleccionado */}
            <StyledFormGroup>
              <Form.Label htmlFor="filtrarPor">
                BUSCAR COINCIDENCIA:{" "}
              </Form.Label>
              <Form.Control
                as="select"
                id="filtrarPor"
                name="filtrarPor"
                value={filtrarPor}
                onChange={(e) => modificarFiltro(e)}
              >
                <option value="nombre_cliente">Por nombre cliente</option>
                <option value="tipo_venta">Por tipo de venta</option>
                <option value="tipo_pago">Por tipo de pago</option>
                <option value="status">Por status</option>
                <option value="vendedor">Por nombre vendedor</option>
              </Form.Control>
              <Form.Control
                type="text"
                // {...register("buscar")}
                value={buscar}
                name="buscar"
                onChange={(e) => modificarFiltro(e)}
                autoComplete="off"
              ></Form.Control>
            </StyledFormGroup>

            {/* Ordenar por campo seleccionado */}
            <StyledFormGroup>
              <Form.Label htmlFor="ordenarPor">ORDENAR TABLA:</Form.Label>
              <Form.Control
                as="select"
                id="ordenarPor"
                name="ordenarPor"
                value={ordenarPor}
                onChange={(e) => modificarFiltro(e)}
              >
                {/* <option value="defecto">Por defecto</option> */}
                <option value="fecha_recientes">
                  Por fecha (Más recientes primero)
                </option>
                <option value="fecha_antiguos">
                  Por fecha (Más antiguas primero)
                </option>
                <option value="cliente">Por nombre cliente</option>
                <option value="vendedor">Por nombre vendedor</option>
              </Form.Control>
            </StyledFormGroup>

            {/* Filtrar por rango de fechas */}
            <StyledFormGroup>
              <Form.Label>FILTRAR ENTRE RANGOS DE FECHAS</Form.Label>
              <StyledFormControlContainer>
                <Form.Control
                  type="date"
                  id="fechaInicio"
                  name="fechaInicio"
                  value={fechaInicio}
                  onChange={(e) => modificarFiltro(e)}
                />
                <Form.Control
                  type="date"
                  id="fechaFinal"
                  name="fechaFinal"
                  value={fechaFinal}
                  onChange={(e) => modificarFiltro(e)}
                  // value={fechaFinal}
                />
              </StyledFormControlContainer>
            </StyledFormGroup>
            <StyledBotonContainer>
              <StyledBoton
                className="btn btn-primary"
                type="submit"
                color="green"
                disabled={false}
                ref={actualizarRef}
                onFocus={() => actualizarRef.current.blur()}
              >
                Actualizar Lista
              </StyledBoton>
            </StyledBotonContainer>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default FiltroListaVentas;
