import React, { useEffect, useRef, useState } from "react";
import {
  DropdownContainer,
  DropdownHeader,
  DropdownOptions,
  Option,
} from './styles/CustomDropDown.styles';

const CustomDropDown = ({ clientesVenta, searchQuery, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  //   This is for closing the select when the user clicks everywhere in the page
  const containerRef = useRef(null);

  const handleSelect = (cliente) => {
    setSelected(cliente);
    onChange(cliente?.id); // use optional chaining in case cliente is null
  };

  const searchQueryLowerCase = searchQuery.toLowerCase();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={containerRef} onClick={() => setIsOpen(!isOpen)} isopen={isOpen}>
      <DropdownHeader>
        {selected ? selected.NOMBRE : "Selecciona un cliente"}
      </DropdownHeader>
      {isOpen && (
        <DropdownOptions>
          {/* Default option to reset selection */}
          <Option onClick={() => handleSelect(null)}>
            Selecciona un cliente
          </Option>
          {clientesVenta.map((c, index) => (
            <Option key={index} onClick={() => handleSelect(c)}>
              {c.NOMBRE.split("").map((char, charIndex) => {
                const charLowerCase = char.toLowerCase();
                return searchQueryLowerCase.includes(charLowerCase) ? (
                  <strong
                    key={charIndex}
                    style={{ fontWeight: "bold", color: "blue" }}
                  >
                    {char}
                  </strong>
                ) : (
                  char
                );
              })}
            </Option>
          ))}
        </DropdownOptions>
      )}
    </DropdownContainer>
  );
};

export default CustomDropDown;
