import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../componentes/general/styles/Boton.styles";

export const StyledContainer = styled(Container)`
  height: 88vh;
  padding: 1rem 0;
  overflow: auto;
  background: linear-gradient(
    rgb(54, 54, 82),
    15%,
    rgb(84, 106, 144),
    60%,
    rgb(68, 111, 151)
  );

  h1 {
    text-align: center;
    color: var(--white-color);
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    height: 88vh;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
  /* display: flex; */
  align-items: center;
  max-width: 120rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledFormGroup = styled(Form.Group)`
  width: 50%;
  min-width: 200px;
  label {
    color: var(--font-color-label);
    font-weight: var(--font-weight-label);
    font-size: var(--font-size-label);
  }

  input,
  select {
    color: var(--font-color-input);
    font-weight: var(--font-weight-input);
    font-size: var(--font-size-input);
    margin-bottom: 1rem;
  }
`;

export const StyledBoton = styled(StyledButton)`
  max-width: 200px;
  height: 50px;
  margin: 15px 0;
  font-size: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledFormControlNombreUsuario = styled(Form.Control)`
  border: ${(props) => (props.error ? "3px solid red" : null)};

  :focus {
    border: ${(props) => (props.error ? "3px solid red" : null)};
  }
`;
