import {
  RESET_CLIENTE_LISTA,
  RESET_CLIENTE_VENTA_LISTA,
} from "../constantes/clienteConstantes";
import {
  REQUEST_PRODUCTO_LISTA,
  SUCCESS_PRODUCTO_LISTA,
  FAIL_PRODUCTO_LISTA,
  REQUEST_PRODUCTO_DETALLES,
  FAIL_PRODUCTO_DETALLES,
  SUCCESS_PRODUCTO_DETALLES,
  REQUEST_PRODUCTO_ACTUALIZAR,
  FAIL_PRODUCTO_ACTUALIZAR,
  SUCCESS_PRODUCTO_ACTUALIZAR,
  RESET_PRODUCTO_LISTA,
  REQUEST_PRODUCTO_REGISTRAR,
  SUCCESS_PRODUCTO_REGISTRAR,
  FAIL_PRODUCTO_REGISTRAR,
  REQUEST_PRODUCTO_BORRAR,
  SUCCESS_PRODUCTO_BORRAR,
  FAIL_PRODUCTO_BORRAR,
} from "../constantes/productoConstantes";
import { RESET_VENTA_LISTA } from "../constantes/ventaConstantes";
import { actualizarAccessToken } from "./sesionActions";

import axiosInstance from "../lib/axiosConfig";
import axios from "axios";

export const pedirProductosLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_PRODUCTO_LISTA });

  // setTimeout(async () => {
  try {
    const { data } = await axiosInstance.get("api/productos/", {
      cancelToken: cancelToken,
    });

    dispatch({ type: SUCCESS_PRODUCTO_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(error.message);
    }

    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(pedirProductosLista, cancelToken));
    } else {
      dispatch({ type: FAIL_PRODUCTO_LISTA, payload: error.message });
    }
  }
  // }, 5000);
};

// Creador de acciones para pedir el producto con el id del backend
export const obtenerProductoDetalles = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_PRODUCTO_DETALLES });

  try {
    // Recibir respuesta del backend y guardarla en data
    const { data } = await axiosInstance.get(`api/productos/${id}/`, {});

    dispatch({ type: SUCCESS_PRODUCTO_DETALLES, payload: data });
  } catch (error) {
    // Si el backend responde con error de tipo 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(obtenerProductoDetalles, id));
    } else {
      dispatch({ type: FAIL_PRODUCTO_DETALLES, payload: error.message });
    }
  }
};

// Creador de acciones para actualizar producto del backend

export const actualizarProducto =
  (id, formData, setDisabledState) => async (dispatch, getState) => {
    dispatch({ type: REQUEST_PRODUCTO_ACTUALIZAR });

    // Intentar pedir al backend que actualice el producto
    try {
      setDisabledState(true)
      // Obtener token del Redux store
      const {
        usuarioInfo: { token },
      } = getState();

      // Crear header con tipo de datos que se envian y token para autenticacion
      const config = {
        headers: {
          "Content-type": "multipart/form-data", // este tipo de datos se usa para enviar media files, como imagenes
          Authorization: `Bearer ${token}`,
        },
      };

      // Recibir respuesta del backend y guardarla en data
      const { data } = await axiosInstance.put(
        `api/modificar-producto/${id}/`,
        formData,
        config
      );

      dispatch({ type: SUCCESS_PRODUCTO_ACTUALIZAR, payload: data });

      // Los productos siempre se piden al backend cuando entro a la tabla de productos
      // dispatch({ type: RESET_PRODUCTO_LISTA });
      // Debo volver a pedir la lista de clientes del backend, la razon es que si cambio el nombre del producto el nombre de ese producto en la lista de precios de todos los clientes cambia y hay que actualizar la lista

      dispatch({ type: RESET_CLIENTE_LISTA });
      dispatch({ type: RESET_CLIENTE_VENTA_LISTA });
      // Debo volver a pedir la lista de ventas del backend, la razon es que si cambio el nombre del producto, la venta debe de mostrar el nuevo nombre. Tambien debe mostrar la cantidad disponible del producto

      // COMO CAMBIE EL CAMPO A UN CHAR YA NO CAMBIA EL NOMBRE, PERO SI EL PRODUCTO ES ELIMINADO EL NOMBRE PERMANECE, OSEA QUE PODRIA QUITAR ESTE RESET
      dispatch({ type: RESET_VENTA_LISTA });
      setDisabledState(false)
    } catch (error) {
      // Si el backend responde con error de tipo 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(actualizarProducto, id, formData));
      } else {
        dispatch({ type: FAIL_PRODUCTO_ACTUALIZAR, payload: error.message });
      }
      setDisabledState(false)
    }
  };

// Creador de acciones para registrar un nuevo producto en el backend
export const registrarProducto = (formData, setDisabledState) => async (dispatch, getState) => {
  dispatch({ type: REQUEST_PRODUCTO_REGISTRAR });

  // Intentar pedir al backend registrar un nuevo cliente
  try {
    setDisabledState(true)
    // Obtener el token del Redux store
    const {
      usuarioInfo: { token },
    } = getState();

    // Crear header con el tipo de datos que se envian y el token para autenticacion
    const config = {
      headers: {
        "Content-Type": "multipart/form-data", // este tipo de datos se usa para enviar media files, como imagenes
        Authorization: `Bearer ${token}`,
      },
    };

    // Recibir la respuesta del backend y guardarla en data
    const { data } = await axiosInstance.post(
      "api/crear-producto/",
      formData,
      config
    );

    dispatch({ type: SUCCESS_PRODUCTO_REGISTRAR, payload: data });
    dispatch({ type: RESET_PRODUCTO_LISTA });
    // Debo volver a pedir la lista de clientes del backend, la razon es que cuando se crea un nuevo producto se le asigna el precio de ese producto a todos los clientes y se debe actualizar la lista de clientes para ver estos precios
    dispatch({ type: RESET_CLIENTE_LISTA });
    dispatch({ type: RESET_CLIENTE_VENTA_LISTA });
    setDisabledState(false)
  } catch (error) {
    // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar ek token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(registrarProducto, formData));
    } else {
      dispatch({ type: FAIL_PRODUCTO_REGISTRAR, payload: error.message });
    }
    setDisabledState(false)
  }
};

// Creador de acciones para borrar un producto en el backend
export const borrarProducto = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_PRODUCTO_BORRAR });

  // Intentar pedir al backend borrar el producto
  try {
    // Recibir respuesta del backend y guardar en data
    const { data } = await axiosInstance.delete(
      `api/modificar-producto/${id}/`
    );

    dispatch({ type: SUCCESS_PRODUCTO_BORRAR, payload: data });
    dispatch({ type: RESET_PRODUCTO_LISTA });

    // Reset lista de clientes para obtener la lista de clientes actualizada sin el producto que se borro
    dispatch({ type: RESET_CLIENTE_LISTA });

    dispatch({ type: RESET_CLIENTE_VENTA_LISTA });
    // Reset lista de ventas para obtener la lista de ventas actualizada sin el producto que se borro
    dispatch({ type: RESET_VENTA_LISTA });
  } catch (error) {
    // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(borrarProducto, id));
    } else {
      dispatch({ type: FAIL_PRODUCTO_BORRAR, payload: error.message });
    }
  }
};
