import { Table } from "react-bootstrap";
import styled from "styled-components";

// Estilos de la tabla
export const TableStyled = styled(Table)`
  margin: 0;
  tbody {
    height: 50vh;
    display: block;
    overflow: auto;

  // Modificación de la scrollbar para Chrome y Edge
  &::-webkit-scrollbar{
    width: 7px;
    background-color: rgb(105,133,169);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb{
    background-color: rgba(230,230,230);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover{
    background-color: rgba(150,150,150);
    border-radius: 5px;
  }

  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;

    i {
      font-size: 25px;
    }
  }
`;
