import axios from "axios";
import { BASE_URL } from "../constantes/constantes";
import { getToken } from "./tokenManager";

// An instance only can have static values. So token access can't go here
const axiosInstance = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
