import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-hot-toast
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import {
  StyledCol,
  StyledContainer,
  StyledRow,
  StyledGridContainer,
  StyledBotonPanel,
  StyledTitulo,
  StyledPanelControl,
  StyledContenidoPrincipal,
  StyledBoton,
} from "./styles/AjusteInventarioLista.styles";

import TablaAjusteInventario from "../componentes/AjusteInventarioLista/TablaAjusteInventario";
import VentanaMostrarAjusteInventario from "../componentes/AjusteInventarioLista/VentanaMostrarAjusteInventario";
import { pedirAjusteInventarioLista, descargarReporteAjusteInventario } from "../actions/ajusteInventario";
import { useMostrarDetallesAjusteInventario } from "../lib/hooks/useMostrarDetallesAjusteInventario";
import axios from "axios";
import FiltroAjusteInventario from "../componentes/AjusteInventarioLista/FiltroAjusteInventario";
import { useLocation } from "react-router-dom";
import PaginacionAjusteInventario from "../componentes/AjusteInventarioLista/PaginacionAjusteInventario";

const AjusteInventarioLista = () => {
  //Obtener referencias de los botones
  const panelMovilRef = useRef(null);
  const descargarRef = useRef(null);
  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Function para obtener el search param en el url
  const location = useLocation();
  const search = location.search;

  // Obtener lista de productos del Redux
  const ajusteInventarioLista = useSelector(
    (state) => state.ajusteInventarioLista
  );
  const { loading, ajusteInventarios, error, page, pages } =
    ajusteInventarioLista;

  const {
    ajusteInventario,
    mostrarModalAjusteInventario,
    manejarMostrarDetallesAjusteInventario,
    manejarCerrarVentana,
  } = useMostrarDetallesAjusteInventario(ajusteInventarios);

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarFormulario, setMostrarFormulario] = useState(true);

  const [disabledState, setDisabledState] = useState(false)

  // useEffect para obtener ajuste inventario del Redux store

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!ajusteInventarios) {
      dispatch(pedirAjusteInventarioLista(search, source.token));
    }

    return () => {
      source.cancel("Petición de lista ajuste inventario cancelada");
    };
  }, [dispatch, ajusteInventarios, search]);

  // Si modifico ajusteInventarios este useEffect no se da cuenta de nada. Por ahora eso no importa porque no puedo modificar ajusteInventarios en este componente
  useEffect(() => {
    const source = axios.CancelToken.source();
    dispatch(pedirAjusteInventarioLista(search, source.token));
    return () => {
      source.cancel("Petición de lista ajuste inventario cancelada");
    };
  }, [dispatch, search]);

  const manejarExportarAjusteInventario = () => {
    dispatch(descargarReporteAjusteInventario(search, setDisabledState));
  };

  // Renderizar loading si se estan cargando ajuste inventario
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de errors si el servidor regresa un error al pedir la lista de productos
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la lista de ajuste inventario
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  // Si se obtienen los productos renderizar la tabla de productos
  return (
    ajusteInventarios && (
      <>
        <StyledGridContainer>
          <StyledBotonPanel
            style={{ top: '13%', right: '10px' }}
            color="standard"
            fontcolor="var(--active-blue-button)"
            ref={panelMovilRef}
            onFocus={() => panelMovilRef.current.blur()}
            onClick={() => setMostrarFormulario((state) => !state)}
            state={mostrarFormulario}
          >
            <i className="fa-solid fa-arrow-right" />
          </StyledBotonPanel>
          <StyledTitulo>Ajuste Inventario</StyledTitulo>
          {/* Panel de control para filtrar y ordenar ventas */}
          <StyledPanelControl mostrarPanel={mostrarFormulario}>
            <FiltroAjusteInventario />

            {/* Exportar ventas */}
            <div>
              <StyledBoton
                type="submit"
                color="green"
                disabled={disabledState}
                ref={descargarRef}
                onFocus={() => descargarRef.current.blur()}
                onClick={() => manejarExportarAjusteInventario()}
              >
                Descargar tabla de ajuste inventario
              </StyledBoton>
            </div>
          </StyledPanelControl>

          {/* Tabla de ventas */}
          <StyledContenidoPrincipal>
            <TablaAjusteInventario
              ajusteInventarios={ajusteInventarios}
              manejarMostrarDetallesAjusteInventario={
                manejarMostrarDetallesAjusteInventario
              }
            />

            <PaginacionAjusteInventario
              page={page}
              pages={pages}
              search={search}
            />
          </StyledContenidoPrincipal>
        </StyledGridContainer>

        {/* Mostrar ventana con detalles del ajuste inventario */}
        {mostrarModalAjusteInventario && (
          <VentanaMostrarAjusteInventario
            ajusteInventario={ajusteInventario}
            mostrarModalAjusteInventario={mostrarModalAjusteInventario}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default AjusteInventarioLista;
