import {
  FAIL_LOGIN_USUARIO,
  LOGOUT_USUARIO,
  REQUEST_LOGIN_USUARIO,
  SUCCESS_LOGIN_USUARIO,
} from "../constantes/sesionConstantes";
import { toast } from "react-hot-toast";
import { setToken } from "../lib/tokenManager";
import axiosInstance from "../lib/axiosConfig";

// Estos tres hay que separarlos (ESTOS SON LOS UNICOS QUE NO ENVIAN EL ACCESS TOKEN AL SERVIDOR)
export const login = (username, password) => async (dispatch) => {
  dispatch({ type: REQUEST_LOGIN_USUARIO });

  try {
    // En point de inicio de sesion. Este endpoint esta configurado para regresar no solo el access y refresh token, sino tambien informacion del usuario logeado. Por ejempl, sus perminos, imagen y nombre
    const { data } = await axiosInstance.post("api/token/", {
      username,
      password,
    });

    const role = data.role;

    if (role === "REPARTIDOR") {
      dispatch({
        type: FAIL_LOGIN_USUARIO,
        payload: "Repartidor no tiene permiso para acceder a esta aplicación",
      });
    } else {
      // dispatch({ type: SUCCESS_LOGIN_USUARIO, payload: data.access });

      // Why is all information necessary in the localStorage
      localStorage.setItem("accessToken", JSON.stringify(data.access));
      // set token to axios interceptor
      setToken(data.access);
      localStorage.setItem("refreshToken", JSON.stringify(data.refresh));
      // Informacion del usuario
      localStorage.setItem("usuarioId", JSON.stringify(data.id));
      localStorage.setItem("isAdmin", JSON.stringify(data.is_admin));
      localStorage.setItem("name", JSON.stringify(data.name));
      localStorage.setItem("username", JSON.stringify(data.username));
      localStorage.setItem("imagen", JSON.stringify(data.empleado.IMAGEN));

      localStorage.setItem("role", JSON.stringify(role));

      // Dispatch success action only after all localStorage operation
      dispatch({ type: SUCCESS_LOGIN_USUARIO, payload: data.access });
    }
  } catch (error) {
    dispatch({ type: FAIL_LOGIN_USUARIO, payload: error.message });
  }
};

export const logout = () => {
  return (dispatch) => {
    // Puedes borrar todo el contenido del localStorage usando localStorage.clear(). Este método eliminará todos los pares clave-valor almacenados en el objeto localStorage para ese dominio.
    localStorage.clear();

    dispatch({ type: LOGOUT_USUARIO });
    window.location.href = "#/login";
    // navigate("/login");
  };
};

// ESTE ES EL UNICO QUE ENVIA EL REFRESH TOKEN AL SERVIDOR
export const actualizarAccessToken =
  (actionCreator, ...parametrosOpcionales) =>
  async (dispatch) => {
    try {
      // Obtener refresh token del localStorage
      const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

      // Endpoint de actualizacion de access token. Recibir respuesta del backend
      const { data } = await axiosInstance.post("api/token/refresh/", {
        refresh: refreshToken,
      });
      const accessToken = data.access;

      // Guardar el accessToken actualizado en el localStorage, el Redux store y en token para axios
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      // set token for axios interceptor
      setToken(accessToken);
      // set token for Redux store
      dispatch({ type: SUCCESS_LOGIN_USUARIO, payload: accessToken });
      // Volver a disparar la accion en la que ocurrio el error 401
      await dispatch(actionCreator(...parametrosOpcionales));
    } catch (error) {
      toast.error("Tu sesión ha caducado. Inicia sesión de nuevo por favor", {
        duration: 5000,
      });
      dispatch(logout());
    }
  };
