import React from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-hot-toast";

// Importar estilos del componente
import {
  StyledBoton,
  StyledButtonContainer,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledImageUser,
  StyledRow,
} from "./styles/Home.styles";
import ConfirmarCerrarSesion from "../componentes/Home/ConfirmarCerrarSesion";
import { BASE_URL } from "../constantes/constantes";
import { useRef } from "react";

const Home = () => {
  // Obtener la informacion del usuario del localStorage
  const name = getLocalStorage("name");
  const username = getLocalStorage("username");
  const isAdmin = getLocalStorage("isAdmin");
  const imagen = getLocalStorage("imagen");
  const role = getLocalStorage("role");

  //Obtener referencia del boton
  const cerrarRef = useRef(null);

  // Funcion para manejar el cierre de sesion
  const manejarLogout = () => {
    toast((t) => <ConfirmarCerrarSesion t={t} />, {
      duration: 2000,
    });
  };

  return (
    <StyledContainer fluid>
      <StyledRow>
        {/* Columna con la imagen del usuario */}
        <StyledCol md={6}>
          <StyledImageUser src={`${BASE_URL}${imagen}`} />
        </StyledCol>
        {/* Columna con la informacion del usuario */}
        <StyledCol md={6}>
          <Form>
            <StyledFormGroup>
              <Form.Label>Nombre:</Form.Label>
              <Form.Control type="text" readOnly value={name}></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup>
              <Form.Label>Usuario:</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={username}
              ></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup>
              <Form.Label>Permisos:</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={isAdmin ? "ADMINISTRADOR" : "NO ES ADMINISTRADOR"}
              ></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup>
              <Form.Label>PUESTO:</Form.Label>
              <Form.Control type="text" readOnly value={role}></Form.Control>
            </StyledFormGroup>

            <StyledButtonContainer>
              <StyledBoton
                color="green"
                disabled={false}
                ref={cerrarRef}
                onFocus={() => cerrarRef.current.blur()}
                onClick={manejarLogout}
              >
                Cerrar Sesión
              </StyledBoton>
            </StyledButtonContainer>
          </Form>
        </StyledCol>
      </StyledRow>
    </StyledContainer>
  );
};

const getLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return JSON.parse(item);
  // try {
  //   const item = localStorage.getItem(key);
  //   return item ? JSON.parse(item) : null;
  // } catch (e) {
  //   console.error(`Error parsing localStorage item ${key}:`, e);
  //   return null; // or some default value, depending on your application's needs
  // }
};

export default Home;
