import { useState } from "react";

export const useProductosCliente = () => {
  // const [nombre, setNombre] = useState("");
  const [productosCliente, setProductosCliente] = useState([]);
  const [mostrarProductos, setMostrarProductos] = useState(false);
  // Esta funcion es necesaria debido a que el estado preciosCliente es una array y no es posible usar setPreciosCliente de forma directa en el formulario
  const manejarCambioPrecio = (nuevoPrecio, precioId) => {
    // Obtener el index del producto cuyo precio hay que cambiar
    const indexPrecio = productosCliente.findIndex(
      (precio) => precio.id === precioId
    );

    // Crear una copia del arreglo de precios
    const nuevosProductosCliente = [...productosCliente];

    // Actualizar el precio con el index seleccionado
    nuevosProductosCliente[indexPrecio] = {
      ...productosCliente[indexPrecio],
      PRECIO: nuevoPrecio,
    };

    setProductosCliente(nuevosProductosCliente);
  };

  return {
    productosCliente,
    mostrarProductos,
    setProductosCliente,
    manejarCambioPrecio,
    setMostrarProductos,
  };
};
