import { useState } from "react";

export const useFormRealizarVenta = () => {
  const [formVenta, setFormVenta] = useState({
    tipoPago: "CONTADO",
    status: "REALIZADO",
    observaciones: "",
    montoDescuento: 0,
  });

  const setTipoPago = (newTipoPago) =>
    setFormVenta((prevFormVenta) => ({
      ...prevFormVenta,
      tipoPago: newTipoPago,
    }));

  const setStatus = (newStatus) =>
    setFormVenta((prevFormVenta) => ({ ...prevFormVenta, status: newStatus }));

  const setObservaciones = (newObservaciones) =>
    setFormVenta((prevFormVenta) => ({
      ...prevFormVenta,
      observaciones: newObservaciones,
    }));

  // Monto de la venta
  const setMontoDescuento = (newMontoDescuento) =>
    setFormVenta((prevFormVenta) => ({
      ...prevFormVenta,
      montoDescuento: newMontoDescuento,
    }));

  return {
    ...formVenta,
    setTipoPago,
    setStatus,
    setObservaciones,
    setMontoDescuento,
  };
};
