import { useEffect, useState } from "react";

// Este es el formato de rutaDay. La llave es el dia y el valor es el id del RutaDia correspondiente

// const rutaDays = {
//   DOMINGO: 63,
//   JUEVES: 60,
//   LUNES: 57,
//   MARTES: 58,
//   MIERCOLES: 59,
//   SABADO: 62,
//   VIERNES: 61,
// };

export const useRutasCliente = (rutasRedux = [], cliente) => {
  // Estado para las rutas del cliente
  const [rutas, setRutas] = useState({
    nombre: "", // todas las rutas tiene el mismo nombre, lo que cambia es el dia
    rutaDays: undefined, // usamos undefines para que la ventana de formulario para rutas no muestre los dias de la semana si el nombre de la ruta no esta definido. No tiene sentido mostrar los dias de la semana para una ruta cuyo nombre no existe
    selectedIds: [],
  });

  //   Este useEffect se ejecuta al montar el componente y crea la ruta del cliente
  useEffect(() => {
    // Si no hay clientes, o no hay ruta, el cliente no tiene ninguna ruta asociada, no hagas nada
    if (!cliente || !rutasRedux || cliente.RUTAS.length === 0) return;

    // Primero obtengo el nombre de las RUTAS del cliente. Todas las RUTAS tiene el mismo nombre, asi que selecciono la primer ruta
    const rutaName = cliente.RUTAS[0].NOMBRE;

    // Dentro de las rutasRedux, buscar la ruta con el nombre de la ruta del cliente.
    const newRuta = { ...rutasRedux.find((r) => r.NOMBRE === rutaName) };

    // Obtener los ids de la ritaDia del cliente
    const initialRutaDayIds = cliente.RUTAS.map((ruta) => ruta.id);

    setRutas({
      nombre: rutaName, // proviene de la ruta y del cliente
      rutaDays: newRuta.ruta_dias, // seleccionar el diccionario con las rutaDays (key/DIA: value/rutaDayId) de esa ruta.
      selectedIds: initialRutaDayIds, // proviene del cliente. selectedIds es un subconjunto de los valores en rutaDays
    });
  }, [cliente, rutasRedux]);

  const [mostrarRutas, setMostrarRutas] = useState(false);

  //   Esta funcion agrega o remueve los selectedIds
  const modificarDayIds = (dayId, add) => {
    if (add) {
      const newSelectedIds = [...rutas.selectedIds, dayId];
      setRutas({ ...rutas, selectedIds: newSelectedIds });
    } else {
      const newSelectedIds = rutas.selectedIds.filter((d) => d !== dayId);
      setRutas({ ...rutas, selectedIds: newSelectedIds });
    }
  };

  //   Esta funcion modifica la ruta y deselecciona todos los dias de la semana
  const modificarRuta = (rutaNombre) => {
    // Si no hay un nombre para la ruta regresa a los valores por default
    if (!rutaNombre) {
      setRutas({
        nombre: "",
        rutaDays: undefined, // usamos undefined para que la ventana de formulario para rutas no muestre los dias de la semana si el nombre de la ruta no esta definido. No tiene sentido mostrar los dias de la semana para una ruta cuyo nombre no existe
        selectedIds: [],
      });

      return;
    }
    // Si el nombre de la ruta existe, encontrar la ruta con ese nombre en la lista de rutas
    const newRuta = { ...rutasRedux.find((r) => r.NOMBRE === rutaNombre) };

    setRutas({
      nombre: rutaNombre,
      rutaDays: newRuta.ruta_dias,
      selectedIds: [], // deselecciona todos los dias de la semana
    });
  };

  return {
    rutas,
    modificarDayIds,
    modificarRuta,
    mostrarRutas,
    setMostrarRutas,
  };
};
