import { useState } from "react";

export const useMostrarModalVenta = (ventas) => {
  const [venta, setVenta] = useState({});
  const [mostrarVenta, setMostrarVenta] = useState(false);

  const manejarCerrarVentana = () => {
    setMostrarVenta(false);
  };

  const manejarMostrarModalVenta = (ventaId) => {
    const ventaSeleccionada = { ...ventas.find((c) => c.id === ventaId) };

    setVenta(ventaSeleccionada);
    setMostrarVenta(true);
  };

  return {
    mostrarVenta,
    venta,
    manejarCerrarVentana,
    manejarMostrarModalVenta,
  };
};
