import styled from "styled-components";

export const DropdownContainer = styled.div`
  position: relative;
  /* width: 200px; */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const DropdownHeader = styled.div`
  padding: 10px;
  /* height: 40px; */
  background-color: #f2f2f2;
  color: black;
  user-select: none;
  max-height: 60px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DropdownOptions = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 180px;
  overflow-y: auto;
  z-index: 1;
`;

export const Option = styled.div`
  padding: 3px 10px 7px 10px;
  padding-left: 10px;
  padding-bottom: 4px;
  height: fit-content;
  cursor: pointer;
  user-select: none;
  background-color: #f2f2f2;
  &:hover {
    background-color: lightblue;
    color: black;
  }
`;