import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  actualizarCliente,
  obtenerClienteDetalles,
} from "../actions/clienteActions";
import Loader from "../componentes/general/Loader";
import {
  RESET_CLIENTE_ACTUALIZAR,
  RESET_CLIENTE_DETALLES,
} from "../constantes/clienteConstantes";
import Mensaje from "../componentes/general/Mensaje";
import { useForm } from "react-hook-form";
import VentanaFormularioPrecios from "../componentes/RegistrarCliente/VentanaFormularioPrecios";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/ClienteDetalles.styles";

import { pedirRutasParaClienteLista } from "../actions/rutaActions";
import VentanaFormularioRuta from "../componentes/RegistrarCliente/VentanaFormularioRuta";
import { useRutasCliente } from "../lib/hooks/useRutasCliente";
import { useProductosCliente } from "../lib/hooks/useProductosCliente";
import { crearNuevosPreciosCliente } from "../lib/utilis/clientes";
import { useRef } from "react";

const ClienteDetalles = () => {
  // Obtener el id del cliente
  const params = useParams();
  const clienteId = params.id;

  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener el estado desde el Redux store
  const clienteDetalles = useSelector((state) => state.clienteDetalles);
  const { loading, cliente, error } = clienteDetalles;

  // Obtener la lista de rutas desde el Redux store
  const rutaLista = useSelector((state) => state.rutaLista);
  const { rutas: rutasRedux } = rutaLista;

  //Obtener referencias de los botones
  const regresarRef = useRef(null);
  const rutasRef = useRef(null);
  const modificarRef = useRef(null);
  const actualizarRef = useRef(null);

  // Obtener el estado desde el Redux store
  const clienteActualizar = useSelector((state) => state.clienteActualizar);
  const {
    loading: actualizarLoading,
    success: actualizarSuccess,
    error: actualizarError,
  } = clienteActualizar;

  // Custom hook para las rutas del cliente
  const {
    rutas,
    mostrarRutas,
    modificarDayIds,
    modificarRuta,
    setMostrarRutas,
  } = useRutasCliente(rutasRedux, cliente);

  // Custom hook para los precios del cliente
  const {
    productosCliente,
    mostrarProductos,
    setProductosCliente, // idealmente no deberia sacar esta funcion del custom hook, es mojor usar una funcion que use adentro a esta funcion, pero parece que en este caso es mas sencillo hacer esto
    manejarCambioPrecio,
    setMostrarProductos,
  } = useProductosCliente();

  // useForm para validar datos del formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // useEffect para mostrar las alertas de validacion del formulario
  useEffect(() => {
    if (errors.ciudad) {
      toast.dismiss();
      toast.error(errors.ciudad.message);
    }

    if (errors.numero) {
      toast.dismiss();
      toast.error(errors.numero.message);
    }

    if (errors.calle) {
      toast.dismiss();
      toast.error(errors.calle.message);
    }

    if (errors.telefono) {
      toast.dismiss();
      toast.error(errors.telefono.message);
    }

    if (errors.nombre) {
      toast.dismiss();
      toast.error(errors.nombre.message);
    }
  }, [
    errors.nombre,
    errors.telefono,
    errors.calle,
    errors.numero,
    errors.ciudad,
  ]);

  // useEffect para mostrar las alertas de actualizacion del cliente
  useEffect(() => {
    let toastId;
    if (actualizarLoading) {
      toastId = toast.loading("Actualizando cliente");
    }

    if (actualizarSuccess) {
      toast.dismiss(toastId);
      toast.success("Cliente actualizado");
      // Reset cliente actualizar para que no se ejecute este bloque de codigo cada vez que se ingrese a cliente detalles
      dispatch({ type: RESET_CLIENTE_ACTUALIZAR });
      navigate("/clientes");
    }

    if (actualizarError) {
      toast.dismiss(toastId);
      toast.error("Error al actualizar cliente");
    }
  }, [
    actualizarSuccess,
    actualizarError,
    actualizarLoading,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // useEffect para pedir el cliente
  useEffect(() => {
    // Si no hay cliente o el cliente no es el que seleccione, disparar la accion de obtener cliente
    if (!cliente || cliente.id !== Number(clienteId)) {
      dispatch(obtenerClienteDetalles(clienteId));
    } else {
      //  reset es una función proporcionada por el useForm que puede utilizarse para establecer nuevos valores por defecto en el formulario en cualquier momento.
      reset({
        nombre: cliente.NOMBRE,
        contacto: cliente.CONTACTO,
        telefono: cliente.TELEFONO,
        correo: cliente.CORREO,
        tipoPago: cliente.TIPO_PAGO,
        calle: cliente.DIRECCION.CALLE,
        numero: cliente.DIRECCION.NUMERO,
        colonia: cliente.DIRECCION.COLONIA,
        ciudad: cliente.DIRECCION.CIUDAD,
        municipio: cliente.DIRECCION.MUNICIPIO,
        codigoPostal: cliente.DIRECCION.CP,
        observaciones: cliente.OBSERVACIONES,
      });
      // cliente.precios_cliente es una arreglo donde cada elemento es un objeto con la siguiente informacion:
      // id(pin):43
      // producto_nombre(pin):"NUEVO PRODUCTO UPDATED"
      // producto_cantidad(pin):150
      // producto_imagen(pin):"/media/imagenes/productos/usuario_default.png"
      // porcentage_precio(pin):80 ESTE ES EL PRECIO DE ESTE CLIENTE, NO EL PRECIO GENERAL

      setProductosCliente(cliente.precios_cliente);
    }
  }, [dispatch, cliente, clienteId, navigate, reset, setProductosCliente]);

  // useEffect para cargar la lista de rutas
  useEffect(() => {
    // if (!rutasRedux) {
    dispatch(pedirRutasParaClienteLista());
    // }
  }, [dispatch]);

  const manejarActualizarCliente = (data) => {
    // Disparar la accion de actualizar cliente
    setDisabledState(!disabledState);

    // Esta funcion permite crear un array de precios con el formato que recibe el backend
    const nuevosPreciosCliente = crearNuevosPreciosCliente(productosCliente);

    dispatch(
      actualizarCliente({
        // El id es para el endpoint, no como informacion de actualizacion
        id: clienteId,
        NOMBRE: data.nombre,
        CONTACTO: data.contacto,
        TELEFONO: data.telefono,
        CORREO: data.correo,
        TIPO_PAGO: data.tipoPago,
        nuevaDireccion: {
          direccionClienteId: cliente.DIRECCION.id,
          CALLE: data.calle,
          NUMERO: data.numero,
          COLONIA: data.colonia,
          CIUDAD: data.ciudad,
          MUNICIPIO: data.municipio,
          CP: data.codigoPostal,
        },
        nuevosPreciosCliente: nuevosPreciosCliente,
        OBSERVACIONES: data.observaciones,
        nuevasRutasIds: rutas.selectedIds,
      })
    );
  };

  const manejarRegresar = () => {
    // Redireccionar a la pagina de clientes
    dispatch({ type: RESET_CLIENTE_DETALLES });
    navigate("/clientes");
  };

  // Renderizar loading si se esta cargando la informacion del cliente
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de error si el servidor regresa un error al pedir la informacion del cliente
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow style={{ height: "80%" }}>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la lista de clientes
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  return (
    cliente && (
      <>
        <StyledContainer fluid>
          <StyledRow>
            <StyledCol>
              <h1>Cliente #{cliente.id}</h1>
              <div>
                <StyledBoton
                  color="green"
                  disabled={false}
                  ref={regresarRef}
                  onFocus={() => regresarRef.current.blur()}
                  onClick={manejarRegresar}
                >
                  Regresar
                </StyledBoton>
              </div>
            </StyledCol>
          </StyledRow>

          <Form onSubmit={handleSubmit(manejarActualizarCliente)}>
            <StyledRow>
              <StyledCol md={4}>
                <StyledFormGroup controlId="nombre">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    {...register("nombre", {
                      required: "Por favor, introduce el nombre del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Contacto */}
                <StyledFormGroup controlId="contacto">
                  <Form.Label>Nombre Contacto (Opcional)</Form.Label>
                  <Form.Control
                    {...register("contacto")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Telefono */}
                <StyledFormGroup controlId="telefono">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    {...register("telefono", {
                      required: "Por favor, introduce el teléfono del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Correo */}
                <StyledFormGroup controlId="correo">
                  <Form.Label>Correo (Opcional)</Form.Label>
                  <Form.Control
                    {...register("correo")}
                    type="email"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledBoton
                    type="button"
                    color="green"
                    disabled={false}
                    ref={rutasRef}
                    onFocus={() => rutasRef.current.blur()}
                    onClick={() => setMostrarRutas(true)}
                  >
                    Seleccionar Rutas
                  </StyledBoton>
                </div>
              </StyledCol>

              <StyledCol md={4}>
                {/* Tipo de pago */}
                <StyledFormGroup controlId="tipoPago">
                  <Form.Label>Tipo de pago</Form.Label>
                  <Form.Control {...register("tipoPago")} as="select">
                    <option value="EFECTIVO">EFECTIVO</option>
                    <option value="CREDITO">CREDITO</option>
                  </Form.Control>
                </StyledFormGroup>
                {/* Calle */}
                <StyledFormGroup controlId="calle">
                  <Form.Label>Calle</Form.Label>
                  <Form.Control
                    {...register("calle", {
                      required: "Por favor, introduce la calle del cliente",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Numero */}
                <StyledFormGroup controlId="numero">
                  <Form.Label>Número</Form.Label>
                  <Form.Control
                    {...register("numero", {
                      required: "Por favor, introduce el número en la calle",
                    })}
                    type="text"
                    step="any"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Colonia */}
                <StyledFormGroup controlId="colonia">
                  <Form.Label>Colonia (Opcional)</Form.Label>
                  <Form.Control
                    {...register("colonia")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                <div>
                  <StyledBoton
                    color="green"
                    disabled={false}
                    ref={modificarRef}
                    onFocus={() => modificarRef.current.blur()}
                    onClick={() => setMostrarProductos(true)}
                    type="button"
                  >
                    Modificar precios
                  </StyledBoton>
                </div>
              </StyledCol>

              <StyledCol md={4}>
                {/* Ciudad */}
                <StyledFormGroup controlId="ciudad">
                  <Form.Label>Ciudad</Form.Label>
                  <Form.Control
                    {...register("ciudad", {
                      required: "Por favor, introduce la ciudad",
                    })}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Municipio */}
                <StyledFormGroup controlId="municipio">
                  <Form.Label>Municipio (Opcional)</Form.Label>
                  <Form.Control
                    {...register("municipio")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Codigo postal */}
                <StyledFormGroup controlId="codigoPostal">
                  <Form.Label>C.P (Opcional)</Form.Label>
                  <Form.Control
                    {...register("codigoPostal")}
                    type="number"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                {/* Observaciones */}
                <StyledFormGroup controlId="observaciones">
                  <Form.Label>Observaciones (Opcional)</Form.Label>
                  <Form.Control
                    {...register("observaciones")}
                    type="text"
                    autoComplete="off"
                  ></Form.Control>
                </StyledFormGroup>

                <div>
                  <StyledBoton
                    type="submit"
                    color="green"
                    disabled={disabledState}
                    ref={actualizarRef}
                    onFocus={() => actualizarRef.current.blur()}
                  >
                    Actualizar cliente
                  </StyledBoton>
                </div>
              </StyledCol>
            </StyledRow>
          </Form>
        </StyledContainer>

        {/* Formulario de precios del cliente */}
        <VentanaFormularioPrecios
          productos={productosCliente}
          mostrarPrecios={mostrarProductos}
          manejarCerrarVentana={() => setMostrarProductos(false)}
          manejarCambioPrecio={manejarCambioPrecio}
        />

        {/* Formulario de rutas del cliente */}
        <VentanaFormularioRuta
          // Estado del componente
          rutas={rutas}
          // Esto del Redux
          rutasRedux={rutasRedux}
          // Funciones para modificar el estado del componente
          modificarRuta={modificarRuta}
          modificarDayIds={modificarDayIds}
          // Mostrar ventana con rutas
          mostrarRutas={mostrarRutas}
          manejarCerrarVentana={() => setMostrarRutas(false)}
        />
      </>
    )
  );
};

export default ClienteDetalles;
