import React from "react";
import {ServerLoader} from '../general/styles/Loaders.styles'

/*const StyledSpinner = styled(Spinner)`
  height: 100px;
  width: 100px;
  margin: auto;
  margin-top: 10px;
  display: block;
`;*/

const Loader = () => {
  return <ServerLoader/>;
};

export default Loader;
