import { useState } from "react";

export const useMostrarDetallesCliente = (clientes) => {
  const [mostrarCliente, setMostrarCliente] = useState(false);
  const [cliente, setCliente] = useState({});

  const manejarCerrarVentana = () => {
    setMostrarCliente(false);
  };

  const manejarMostrarDetallesCliente = (clienteId) => {
    const clienteSeleccionado = { ...clientes.find((c) => c.id === clienteId) };
    setCliente(clienteSeleccionado);
    setMostrarCliente(true);
  };

  return {
    cliente,
    mostrarCliente,
    manejarMostrarDetallesCliente,
    manejarCerrarVentana,
  };
};
