import { Table } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

export const TableStyled = styled(Table)`
  height: 100%;
  tbody {
    height: 70vh;
    width: 100vw;
    display: block;
    overflow: auto;
    
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar{
      display: none;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    color: white;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;
  }

  @media screen and (max-width: 400px){
    Button{
      width: 40px;
      height: 40px;
      font-size: 22px;
      margin: 0;
      padding: 0;
    }
  }

  @media screen and (max-width: 400px){
    font-size: 12px;
  }
`;

export const StyledDeleteButton = styled(StyledButton)`
  i{
    font-size: 20px;
  }

  @media screen and (max-width: 1100px) {
   height: 40px;
   width: 40px;
   padding: 5px;

    @media screen and (max-width: 450px){
    width: 30px;
    height: 30px;
    padding: 5px;
    
    i{
      font-size: 17px;
    }
  }
  }

`;


export const StyledGenericButton = styled(StyledButton)`
  color: var(--active-blue-button);
  font-weight: bold;

  i{
    font-size: 20px;
  }

  @media screen and (max-width: 1100px){
    width: 40px;
    height: 40px;
    padding: 5px;

    @media screen and (max-width: 450px){
    width: 30px;
    height: 30px;
    padding: 5px;
    
    i{
      font-size: 17px;
    }
  }    
  }
`;

export const StyledTD = styled.div`
  color: white;
  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.lines};
 
  @media screen and (max-width: 680px){
    font-size: 14px;

    @media screen and (max-width:400px){
      font-size: 12px;
    }
  }
`;

export const StyledOptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;