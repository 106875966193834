import { Modal } from "react-bootstrap";
import {
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  TablaEstilo,
} from "./styles/VentanaMostrarResumenVentas.styles";
import { StyledButton } from "../general/styles/Boton.styles";
import {
  calculateOverallTotal,
  getSalesByIndividualTypeAndPayment,
  getSalesByTypeAndPaymentMethod,
} from "../../lib/utilis/resumenVentas";
import { useRef } from "react";

const VentanaMostrarResumenVentas = ({
  mostrarResumenVentas,
  manejarCerrarResumenVentas,
  ventas,
}) => {
  const fileteredVentas = ventas.filter((v) => v.STATUS === "REALIZADO");

  // Obtener ventas clasificadas por tipo de venta y tipo de pago
  const TotalVentasPorTipoVentayPago =
    getSalesByIndividualTypeAndPayment(fileteredVentas);

  // Obtener la referencia del boton
  const cerrarRef = useRef(null);

  const TotalVentasPorTipo = getSalesByTypeAndPaymentMethod(fileteredVentas);

  return (
    ventas && (
      <Modal
        centered
        show={mostrarResumenVentas}
        onHide={manejarCerrarResumenVentas}>
        <StyledModalHeader>
          <h4>Resumen de las ventas</h4>
        </StyledModalHeader>

        <StyledModalBody>
          <TablaEstilo>
            <thead>
              <tr>
                <th>Tipo de venta</th>
                <th>Número de ventas</th>
                <th>Monto Total</th>
              </tr>
            </thead>
            <tbody>
              {TotalVentasPorTipoVentayPago.map((venta, index) => (
                <tr key={index}>
                  <td>{venta.tipo}</td>
                  <td>{venta.numeroVentas}</td>
                  <td>
                    {"$"}
                    {venta["MONTO TOTAL"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </TablaEstilo>

          <TablaEstilo>
            <thead>
              <tr>
                <th>Tipo de venta</th>
                <th>Número de ventas</th>
                <th>Monto Total</th>
              </tr>
            </thead>
            <tbody>
              {TotalVentasPorTipo.map((venta, index) => (
                <tr key={index}>
                  <td>{venta.tipo}</td>
                  <td>{venta.numeroVentas}</td>
                  <td>
                    {"$"}
                    {venta["MONTO TOTAL"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </TablaEstilo>

          <h5>Total:</h5>

          <p>${calculateOverallTotal(TotalVentasPorTipoVentayPago)}</p>
        </StyledModalBody>

        <StyledModalFooter>
          <StyledButton
            color="blue"
            disabled={false}
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            variant="secondary"
            onClick={() => {
              manejarCerrarResumenVentas();
            }}>
            Cerrar
          </StyledButton>
        </StyledModalFooter>
      </Modal>
    )
  );
};

export default VentanaMostrarResumenVentas;
