// Usuarios

// Pedir lista de usuarios
export const REQUEST_USUARIO_LISTA = "REQUEST_USUARIO_LISTA";
export const SUCCESS_USUARIO_LISTA = "SUCCESS_USUARIO_LISTA";
export const FAIL_USUARIO_LISTA = "FAIL_USUARIO_LISTA";
export const RESET_USUARIO_LISTA = "RESET_USUARIO_LISTA";

// Pedir un solo USUARIO
export const REQUEST_USUARIO_DETALLES = "REQUEST_USUARIO_DETALLES";
export const SUCCESS_USUARIO_DETALLES = "SUCCESS_USUARIO_DETALLES";
export const FAIL_USUARIO_DETALLES = "FAIL_USUARIO_DETALLES";
export const RESET_USUARIO_DETALLES = "RESET_USUARIO_DETALLES";

// Actualizar un USUARIO
export const REQUEST_USUARIO_ACTUALIZAR = "REQUEST_USUARIO_ACTUALIZAR";
export const SUCCESS_USUARIO_ACTUALIZAR = "SUCCESS_USUARIO_ACTUALIZAR";
export const FAIL_USUARIO_ACTUALIZAR = "FAIL_USUARIO_ACTUALIZAR";
export const RESET_USUARIO_ACTUALIZAR = "RESET_USUARIO_ACTUALIZAR";

// Registrar un nuevo USUARIO
export const REQUEST_USUARIO_REGISTRAR = "REQUEST_USUARIO_REGISTRAR";
export const SUCCESS_USUARIO_REGISTRAR = "SUCCESS_USUARIO_REGISTRAR";
export const FAIL_USUARIO_REGISTRAR = "FAIL_USUARIO_REGISTRAR";
export const RESET_USUARIO_REGISTRAR = "RESET_USUARIO_REGISTRAR";

// // Borrar un USUARIO
export const REQUEST_USUARIO_BORRAR = "REQUEST_USUARIO_BORRAR";
export const SUCCESS_USUARIO_BORRAR = "SUCCESS_USUARIO_BORRAR";
export const FAIL_USUARIO_BORRAR = "FAIL_USUARIO_BORRAR";
export const RESET_USUARIO_BORRAR = "RESET_USUARIO_BORRAR";
