import { Table } from "react-bootstrap";
import styled from "styled-components";
import { StyledButton } from "../../general/styles/Boton.styles";

export const StyledButtonContainer = styled.div`
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBoton = styled(StyledButton)`
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${props => props.fontcolor};
  left: 0.5vw;
`;

export const StyledLockContainer = styled(StyledButton)`
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--active-red-button);
  left: 0.5vw;

  &:disabled{
    color: var(--active-red-button);
    background-color: var(--disabled-standard-color);
  }
`;

// Estilos de la tabla
export const TableStyled = styled(Table)`
  margin: 0;
  tbody {
    height: 50vh;
    display: block;
    overflow: auto;


    // Modificación de la scrollbar para Chrome y Edge
    &::-webkit-scrollbar{
      width: 7px;
      background-color: rgb(105,133,169);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb{
      background-color: rgba(230,230,230);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover{
      background-color: rgba(150,150,150);
      border-radius: 5px;
    }

  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: white !important;

    i{
      font-size: 25px;
    }
  }
`;
