import { useState } from "react";

// Funcion para mostrar ventana con detalles del producto
export const useMostrarDetallesProducto = (productos) => {
  // Estado para la ventana emergente con la informacion del producto
  const [mostrarProducto, setMostrarProducto] = useState(false);
  // Estado para guardar informacion del producto
  const [producto, setProducto] = useState({});

  // Funcion para cerrar la ventana de informacion del producto
  const manejarCerrarVentana = () => {
    setMostrarProducto(false);
  };

  // Funcion para mostrar ventana emergente con detalles del producto
  const manejarMostrarDetallesProducto = (productoId) => {
    const productoSeleccionado = {
      ...productos.find((c) => c.id === productoId),
    };
    setProducto(productoSeleccionado);
    setMostrarProducto(true);
  };

  return {
    producto,
    mostrarProducto,
    manejarMostrarDetallesProducto,
    manejarCerrarVentana,
  };
};
