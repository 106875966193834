import { useState } from "react";
import { obtenerValoresFiltroVentas } from "../utilis/venta";

export const useFiltrosVentas = () => {
  // Estado para los filtros de las lista de ventas
  const [filtrosVentas, setFiltrosVentas] = useState(
    obtenerValoresFiltroVentas
  );

  const { buscar, filtrarPor, ordenarPor, fechaInicio, fechaFinal } =
    filtrosVentas;

  // Funcion para modificar el valor con keys dinamicas
  const modificarFiltro = (e) =>
    setFiltrosVentas((prevFiltrosVentas) => ({
      ...prevFiltrosVentas,
      [e.target.name]: e.target.value,
    }));

  // Generar el url
  const url = `/ventas?filtrarpor=${filtrarPor}&buscar=${buscar}&ordenarpor=${ordenarPor}&fechainicio=${fechaInicio}&fechafinal=${fechaFinal}`;

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro,
  };
};
