import styled from "styled-components";
import { Form } from "react-bootstrap";
import { StyledButton } from "../../general/styles/Boton.styles";

export const StyledFormGroup = styled(Form.Group)`
  label {
    color: var(--font-color-label);
    font-weight: var(--font-weight-label);
    font-size: var(--font-size-label);
  }

  input,
  select {
    color: var(--font-color-input);
    font-weight: var(--font-weight-input);
    font-size: var(--font-size-input);
    margin-bottom: 1.2rem;
  }
`;

export const StyledBotonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBoton = styled(StyledButton)`
  width: 70%; 
  max-width: 200px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;
  font-size: 0.9rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    min-width: 160px;
  }
`;
