import { Col, Container, Form, Row } from "react-bootstrap";
import {
  StyledFormGroup,
  StyledBoton,
  StyledBotonContainer,
} from "./styles/FiltroListaClientes.styles";
import { useNavigate } from "react-router-dom";
import { useFiltrosClientes } from "../../lib/hooks/useFiltrosClientes";
import { useRef } from "react";
import { guardarFiltrosCliente } from "../../lib/utilis/clientes";

const FiltroListaClientes = () => {
  // Funcion para navegar de regreso a la pagina de clientes con el url modificado
  const navigate = useNavigate();

  //Obtener la referencia del boton
  const actualizarRef = useRef(null);

  // Establecer valore por defecto del formulario
  const { filtrarPor, buscar, ordenarPor, url, modificarFiltro } =
    useFiltrosClientes();

  const manejarSubmit = (e) => {
    e.preventDefault();
    // Guardamos los filtros para recuperarlos despues de renderizar el componente
    guardarFiltrosCliente(buscar, filtrarPor, ordenarPor);
    // Volver a renderizar la pagina de clientes con el url actualizado
    navigate(url);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form onSubmit={manejarSubmit}>
            {/* Buscar por campo seleccionado */}
            <StyledFormGroup>
              <Form.Label htmlFor="filtrarPor">
                BUSCAR COINCIDENCIA:{" "}
              </Form.Label>
              <Form.Control
                as="select"
                id="filtrarPor"
                name="filtrarPor"
                value={filtrarPor}
                onChange={(e) => modificarFiltro(e)}
              >
                <option value="nombre">Por nombre cliente</option>
                <option value="contacto">Por nombre de contacto</option>
                <option value="tipo_pago">Por tipo de pago</option>
              </Form.Control>
              <Form.Control
                type="text"
                value={buscar}
                name="buscar"
                onChange={(e) => modificarFiltro(e)}
                autoComplete="off"
              ></Form.Control>
            </StyledFormGroup>

            {/* Ordenar por campo seleccionado */}
            <StyledFormGroup>
              <Form.Label htmlFor="ordenarPor">ORDENAR TABLA:</Form.Label>
              <Form.Control
                as="select"
                id="ordenarPor"
                name="ordenarPor"
                value={ordenarPor}
                onChange={(e) => modificarFiltro(e)}
              >
                <option value="defecto">Por defecto</option>
                <option value="nombre">Por nombre cliente</option>
                <option value="contacto">Por nombre contacto</option>
              </Form.Control>
            </StyledFormGroup>

            <StyledBotonContainer>
              <StyledBoton
                type="submit"
                color="green"
                ref={actualizarRef}
                disabled={false}
                onFocus={() => actualizarRef.current.blur()}
              >
                Actualizar Lista
              </StyledBoton>
            </StyledBotonContainer>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default FiltroListaClientes;
