import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export const PaginationButton = styled.button`
  padding: 10px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#007bff" : "#f0f0f0")};
  color: ${(props) => (props.selected ? "#ffffff" : "#000000")};
  border-radius: 20px;

  &:hover {
    background-color: ${(props) => (props.selected ? "#0056b3" : "#e0e0e0")};
  }

  &:disabled {
    cursor: not-allowed;
    display: none;
  }
`;
