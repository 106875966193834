import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";

// Colores del boton
const colorStyles = ({ color }) => {
  switch (color) {
    case "red":
      return css`
        background-color: var(--active-red-button);
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: var(--hover-red-button);
          box-shadow: 0px 2px 5px 2px var(--hover-red-button-border);
          color: var(--font-color-input);
        }

        &:disabled {
          background-color: var(--disabled-red-button);
        }

        &:first-child:active {
          color: var(--white-color);
          background-color: var(--active-red-button);
        }

        &:active:focus {
          background-color: var(--active-red-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);
          color: var(--white-color);
        }

        &:focus {
          background-color: var(--active-red-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
          color: var(--white-color);
        }

        &:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-red-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }

        &:active:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-red-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }
      `;

    case "green":
      return css`
        background-color: var(--active-green-button);
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: #00a100;
          box-shadow: 0px 2px 5px 2px var(--hover-green-button-border);
          color: var(--font-color-input);
        }

        &:disabled {
          background-color: var(--disabled-green-button);
        }

        &:first-child:active {
          color: var(--white-color);
          background-color: var(--active-green-button);
        }

        &:active:focus {
          background-color: var(--active-green-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);
          color: var(--white-color);
        }

        &:focus {
          background-color: var(--active-green-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
          color: var(--white-color);
        }

        &:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-green-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }

        &:active:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-green-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }
      `;
    case "blue":
      return css`
        background-color: var(--active-blue-button);
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: var(--hover-blue-button);
          box-shadow: 0px 2px 5px 2px var(--hover-blue-button-border);
          color: var(--font-color-input);
        }

        &:disabled {
          background-color: var(--disabled-blue-button);
        }

        &:first-child:active {
          color: var(--white-color);
          background-color: var(--active-blue-button);
        }

        &:active:focus {
          background-color: var(--active-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);
          color: var(--white-color);
        }

        &:focus {
          background-color: var(--active-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
          color: var(--white-color);
        }

        &:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }

        &:active:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }
      `;
    case "standard":
      return css`
        box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: var(--hover-standard-color);
          box-shadow: 0px 2px 5px 2px var(--hover-standard-border-color);
          color: var(--font-color-input);
        }

        &:disabled {
          background-color: var(--disabled-standard-color);
        }

        &:first-child:active {
          color: var(--white-color);
          background-color: var(--active-blue-button);
        }

        &:active:focus {
          background-color: var(--active-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.5);
          color: var(--white-color);
        }

        &:focus {
          background-color: var(--active-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
          color: var(--white-color);
        }

        &:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }

        &:active:focus-visible {
          color: var(--white-color);
          background-color: var(--hover-blue-button);
          box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.6);
        }
      `;
  }
};

export const StyledButton = styled(Button)`
  color: var(--white-color);
  font-weight: bold;
  ${colorStyles}
`;
