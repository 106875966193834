import styled from "styled-components"

export const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const StyledBoxShadow = styled.div`
    width: 70%;
    height: 51%;
    border-radius: 5px;
    max-width: 604px;
    padding: 15px;
    border-color: rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    transform: translateX(2px) translateY(2px);
`;

export const StyledH1 = styled.h1`
    width: 70%;
    max-width: 600px;
    padding: 15px;
    height: 50%;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    color: red;
    font-weight: bold;
    background-color: white;
    border-radius: 10px;
`;