import {
  FAIL_AJUSTE_INVENTARIO_LISTA,
  FAIL_AJUSTE_INVENTARIO_REGISTRAR,
  FAIL_AJUSTE_INVENTARIO_REPORTE_LISTA,
  REQUEST_AJUSTE_INVENTARIO_LISTA,
  REQUEST_AJUSTE_INVENTARIO_REGISTRAR,
  SUCCESS_AJUSTE_INVENTARIO_LISTA,
  SUCCESS_AJUSTE_INVENTARIO_REGISTRAR,
  SUCCESS_AJUSTE_INVENTARIO_REPORTE_LISTA,
} from "../constantes/ajusteInventarioConstantes";
import { actualizarAccessToken } from "./sesionActions";
import {
  RESET_CLIENTE_LISTA,
  RESET_CLIENTE_VENTA_LISTA,
} from "../constantes/clienteConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";
import * as XLSX from "xlsx";
import { modifyJSON } from "../lib/utilis/venta";

export const pedirAjusteInventarioLista =
  (search = "", cancelToken) =>
  async (dispatch) => {
    dispatch({ type: REQUEST_AJUSTE_INVENTARIO_LISTA });

    // setTimeout(async () => {
    // Intentar pedir lista de ajuste inventario al backend
    try {
      // Recibir la respuesta del backend y guardarla en data
      const { data } = await axiosInstance.get(
        `api/ajuste-inventario${search}`,
        {
          cancelToken,
        }
      );
      dispatch({ type: SUCCESS_AJUSTE_INVENTARIO_LISTA, payload: data });
    } catch (error) {
      if (axios.isCancel(error)) console.log(error.message);

      // Si el backend responde con un error 401 (no autorizado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(pedirAjusteInventarioLista, search, cancelToken)
        );
      } else {
        dispatch({
          type: FAIL_AJUSTE_INVENTARIO_LISTA,
          payload: error.message,
        });
      }
    }
    // }, 1500);
  };

export const registrarAjustarInventario =
  (ajusteInventario) => async (dispatch) => {
    dispatch({ type: REQUEST_AJUSTE_INVENTARIO_REGISTRAR });
    try {
      const { data } = await axiosInstance.post(
        "api/crear-ajuste-inventario/",
        ajusteInventario
      );

      // NO ENTIENDO PORQUE DEBO PONER ESTOS CLIENTE RESET ANTES DEL SUCCESS DE AJUSTE INVENTARIO PARA QUE FUNCIONE
      dispatch({ type: RESET_CLIENTE_LISTA });
      dispatch({ type: RESET_CLIENTE_VENTA_LISTA });

      dispatch({ type: SUCCESS_AJUSTE_INVENTARIO_REGISTRAR, payload: data });
      // Debemos actualizar los productos de los clientes con la nueva cantidad
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(registrarAjustarInventario, ajusteInventario)
        );
      } else {
        dispatch({
          type: FAIL_AJUSTE_INVENTARIO_REGISTRAR,
          payload: error.message,
        });
      }
    }
  };

// Creador de acciones para pedir los ventas del backend
export const descargarReporteAjusteInventario =
  (search = "", setDisabledState) =>
    async (dispatch) => {
      dispatch({ type: REQUEST_AJUSTE_INVENTARIO_LISTA });

      // Intentar pedir al backend lista de ajuste inventario
      try {
        setDisabledState(true)
        // Recibir respuesta del backend y guardarla en data
        const { data } = await axiosInstance.get(`api/ajuste-inventario-reporte${search}`);


      dispatch({
        type: SUCCESS_AJUSTE_INVENTARIO_REPORTE_LISTA,
        payload: data,
      });

      // Modify the JSON data
      const ajusteInventario = modifyJSON(data);

      // Convertir JSON a CSV
      const worksheet = XLSX.utils.json_to_sheet(ajusteInventario);
      const csv = XLSX.utils.sheet_to_csv(worksheet);

      // Crear un enlace de descarga para el CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "ajuste-inventario.csv"); // Nombre del archivo CSV
      document.body.appendChild(link);
      link.click();
      setDisabledState(false)

      // Eliminar el enlace una vez descargado
      document.body.removeChild(link);
    } catch (error) {
      // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(
          actualizarAccessToken(descargarReporteAjusteInventario, search)
        );
      } else {
        dispatch({
          type: FAIL_AJUSTE_INVENTARIO_REPORTE_LISTA,
          payload: error.message,
        });
      }
      setDisabledState(false)
    }
  };
