import React from "react";

import { useNavigate } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import {
  PaginationButton,
  PaginationWrapper,
} from "./styles/PaginacionAjusteInventario.styles";

const PaginacionAjusteInventario = ({ page, pages, search = "" }) => {
  const navigate = useNavigate();

  const currentPage = parseInt(page);

  let searchWithoutPage = "";
  if (search === "") {
    searchWithoutPage = "?";
  } else {
    searchWithoutPage = search.replace(/&page=\d+/g, "");
  }

  const goToPage = (p) => {
    navigate(`/ajuste-inventarios${searchWithoutPage}&page=${p}`);
  };

  return (
    <Container style={{ marginTop: "20px" }}>
      <Row>
        <Col>
          <PaginationWrapper>
            <PaginationButton
              onClick={() => goToPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Anterior
            </PaginationButton>

            {[currentPage - 1, currentPage, currentPage + 1].map((p) => (
              <PaginationButton
                key={p}
                onClick={() => goToPage(p)}
                disabled={p < 1 || p > pages}
                selected={p === currentPage}
              >
                {p}
              </PaginationButton>
            ))}

            <PaginationButton
              onClick={() => goToPage(currentPage + 1)}
              disabled={currentPage === pages}
            >
              Siguiente
            </PaginationButton>
          </PaginationWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default PaginacionAjusteInventario;
