import React, { useRef } from "react";
import { Form } from "react-bootstrap";

import {
  StyleSpan,
  StyledBoton,
  StyledForm,
  StyledFormGroup,
} from "./styles/FormularioVenta.styles";
import { toast } from "react-hot-toast";
import {
  calcularMontoTotal,
  crearProductosVentaConSubtotal,
} from "../../lib/utilis/venta";
import { useFormRealizarVenta } from "../../lib/hooks/useFormRealizarVenta";
import CustomDropDown from "./CustomDropDown";

const FormularioVenta = ({
  cliente,
  manejarCambiarCliente,
  clientesVenta,
  manejarLimpiarProductosVenta,
  productosVenta,
  manejarSeleccionarProducto,
  productosCliente,
  setVenta,
  setMostrarVenta,
  deshabilitarVenta,
  searchQuery,
  searchQueryMessage,
  manejarSearchQuery,
}) => {
  // La razon por la que usamos useState y no useForm para este formulario es debido a que no se requiere validacion
  // Hooks para el formulario de venta

  // This will not be neccessary once clientesVentas comes as it should be
  // const [clientOptions, setClientOptions] = useState([]);
  // const fetchClients = (inputValue) => {
  //   console.log("inputValue", inputValue);
  //   const inputValueLowerCase = inputValue.toLowerCase();
  //   const clients = clientesVenta.filter((c) =>
  //     c.NOMBRE.toLowerCase().includes(inputValueLowerCase)
  //   );

  //   setClientOptions(clients);
  // };

  // const handleInputChange = (newValue) => {
  //   manejarCambiarSearchQuery(newValue);
  //   // This will not be necessary
  //   fetchClients(newValue);
  // };

  // useEffect(() => {
  //   handleInputChange(cliente.searchQuery);
  // }, [cliente.searchQuery]);

  const {
    tipoPago,
    status,
    observaciones,
    montoDescuento,
    setTipoPago,
    setStatus,
    setObservaciones,
    setMontoDescuento,
  } = useFormRealizarVenta();

  // SI VAS A RENDERIZAR AL APLICAR LOS CAMBIOS, PIENSA BIEN SI NECESITAS UN useEffect!
  const productosVentaConSubtotal =
    crearProductosVentaConSubtotal(productosVenta);

  const montoSinDescuento = calcularMontoTotal(
    tipoPago,
    productosVentaConSubtotal
  );

  var porcentajeDescuento;
  if (montoDescuento / montoSinDescuento === 0) {
    porcentajeDescuento = 0;
  } else if (montoSinDescuento > 0) {
    porcentajeDescuento = (1 - montoDescuento / montoSinDescuento) * 100;
  } else {
    porcentajeDescuento = 0;
  }

  //   Funcion para crear venta
  const manejarCrearVenta = (e) => {
    e.preventDefault();

    if (
      (tipoPago === "CORTESIA" || montoDescuento !== 0) &&
      status !== "PENDIENTE"
    ) {
      return toast.error(
        "STATUS deber ser pendiente si el tipo de venta es cortesía o existe un descuento"
      );
    }

    if (porcentajeDescuento < 0) {
      return toast.error(
        "El porcentaje de descuento debe ser mayor o igual 0."
      );
    }

    const ventaSubmit = {
      productosVenta: productosVentaConSubtotal,
      VENDEDOR: VENDEDOR,
      MONTO:
        montoDescuento && tipoPago !== "CORTESIA"
          ? montoDescuento
          : montoSinDescuento,
      TIPO_VENTA: "MOSTRADOR",
      TIPO_PAGO: tipoPago,
      STATUS: status,
      OBSERVACIONES: observaciones,
      CLIENTE: cliente.id,
      NOMBRE_CLIENTE: cliente.nombre,
      DESCUENTO: porcentajeDescuento,
      CIUDAD_REGISTRO: cliente.ciudadRegistro,
    };
    setVenta(ventaSubmit);
    setMostrarVenta(true);
  };

  const VENDEDOR = JSON.parse(localStorage.getItem("name"));

  const buttonRef = useRef(null);

  return (
    <StyledForm onSubmit={manejarCrearVenta}>
      <StyledFormGroup controlId="search_query">
        <Form.Label>NOMBRE DEL CLIENTE</Form.Label>
        <Form.Control
          type="text"
          value={searchQuery}
          onChange={(e) => {
            manejarSearchQuery(e.target.value);
            manejarCambiarCliente();
            manejarLimpiarProductosVenta();
          }}
          autoComplete="off"
        />
        {searchQueryMessage && <StyleSpan>{searchQueryMessage}</StyleSpan>}
        {clientesVenta && (
          <CustomDropDown
            clientesVenta={clientesVenta}
            searchQuery={searchQuery}
            onChange={(id) => {
              manejarCambiarCliente(id);
              manejarLimpiarProductosVenta();
            }}
          />
        )}
      </StyledFormGroup>

      <StyledFormGroup controlId="productosCliente">
        <Form.Label>PRODUCTOS DEL CLIENTE</Form.Label>
        <Form.Control
          as="select"
          value={0}
          onChange={(e) => manejarSeleccionarProducto(Number(e.target.value))}
        >
          <option value={0}>Selecciona un producto</option>
          {productosCliente.map((pc) => (
            <option key={pc.id} value={pc.id}>
              {pc.producto_nombre}
            </option>
          ))}
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="tipo_pago">
        <Form.Label>TIPO DE PAGO</Form.Label>
        <Form.Control
          as="select"
          value={tipoPago}
          onChange={(e) => setTipoPago(e.target.value)}
        >
          <option value="CONTADO">Contado</option>
          <option value="CREDITO">Credito</option>
          <option value="CORTESIA">Cortesia</option>
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="status">
        <Form.Label>STATUS</Form.Label>
        <Form.Control
          as="select"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="REALIZADO">Realizado</option>
          <option value="PENDIENTE">Pendiente</option>
        </Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="observaciones">
        <Form.Label>OBSERVACIONES</Form.Label>
        <Form.Control
          required={status === "PENDIENTE"}
          type="text"
          value={observaciones}
          onChange={(e) => setObservaciones(e.target.value)}
          autoComplete="off"
        ></Form.Control>
      </StyledFormGroup>

      <StyledFormGroup controlId="descuento">
        <Form.Label>MONTO DESCUENTO </Form.Label>
        <Form.Control
          required
          type="number"
          value={montoDescuento}
          disabled={deshabilitarVenta}
          min={0}
          step={0.01}
          autoComplete="off"
          onChange={(e) => setMontoDescuento(parseFloat(e.target.value))}
        />
      </StyledFormGroup>

      {montoDescuento ? (
        <p className="text-light fs-6 text-center">
          (Porcentaje Descuento: {porcentajeDescuento.toFixed(2)}%)
        </p>
      ) : (
        <p className="text-light fs-6 text-center">
          (Monto total: ${montoSinDescuento.toFixed(2)})
        </p>
      )}
      <StyledBoton
        color="green"
        disabled={deshabilitarVenta}
        type="submit"
        ref={buttonRef}
        onFocus={() => buttonRef.current.blur()}
      >
        Realizar venta
      </StyledBoton>
    </StyledForm>
  );
};

export default FormularioVenta;
