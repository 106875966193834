import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  RESET_PRODUCTO_BORRAR,
  RESET_PRODUCTO_DETALLES,
} from "../constantes/productoConstantes";
import Loader from "../componentes/general/Loader";
import VentanaMostrarProducto from "../componentes/ProductosLista/VentanaMostrarProducto";
import Mensaje from "../componentes/general/Mensaje";
import ConfirmarBorrarObjeto from "../componentes/general/ConfirmarBorrarObjeto";
import {
  StyledCol,
  StyledContainer,
  StyledRow,
} from "./styles/ProductosLista.styles";
import TablaProductos from "../componentes/ProductosLista/TablaProductos";
import { useMostrarDetallesProducto } from "../lib/hooks/useMostrarDetallesProducto";
import { pedirProductosLista } from "../actions/productoActions";
import axios from "axios";

const ProductosLista = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();
  // Obtener lista de productos del Redux
  const productoLista = useSelector((state) => state.productoLista);
  const { loading, productos, error } = productoLista;

  // Obtener el estado borrar producto del Redux
  const productoBorrar = useSelector((state) => state.productoBorrar);
  const {
    loading: borrarLoading,
    success: borrarSuccess,
    error: borrarError,
  } = productoBorrar;

  const {
    mostrarProducto,
    producto,
    manejarCerrarVentana,
    manejarMostrarDetallesProducto,
  } = useMostrarDetallesProducto(productos);

  // Simepre pedimos la lista de producto al entrar al entrar a este componente
  // If you want to ensure that you always have the most up-to-date data from the server, then you would dispatch the action every time the component mounts. Keep in mind that this approach can be costly in terms of performance and resource usage.
  useEffect(() => {
    // The axios.CancelToken.source() function returns an object that contains a new CancelToken and a cancel function. By passing this token to the request configuration, you can later cancel the request by invoking the cancel function.
    const source = axios.CancelToken.source(); // Initialize Axios Cancel Token Source

    // Dispatch action with the cancel token
    if (!borrarSuccess) {
      dispatch(pedirProductosLista(source.token));
    }

    // Cleanup function to cancel the request if the component is unmounted
    return () => {
      source.cancel("Petición lista productos cancelada");
    };
  }, [dispatch, borrarSuccess]);

  // useEffect para mostrar las alertas de borrar producto
  useEffect(() => {
    let toastId;
    if (borrarLoading) {
      toastId = toast.loading("Eliminando producto");
    }

    if (borrarSuccess) {
      toast.dismiss(toastId);
      toast.success("Producto eliminado exitosamente", {
        duration: 2000,
      });
      // Reset producto borrar para no ejecutar este bloque de codigo cada vez que se ingresa a lista de productos
      dispatch({ type: RESET_PRODUCTO_BORRAR });
    }

    if (borrarError) {
      toast.dismiss(toastId);
      toast.error("Error al eliminar producto", {
        duration: 4000,
      });
    }
  }, [borrarSuccess, borrarError, borrarLoading, dispatch]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // Funcion para redireccionar a la pagina del producto
  const manejarProductoDetalles = (id) => {
    // Resetar informacion de detalles del producto antes de redireccionar
    dispatch({ type: RESET_PRODUCTO_DETALLES });
    navigate(`/productos/${id}`);
  };

  const manejarRealizarAjusteInventario = (id) => {
    // dispatch({ type: RESET_AJUSTE_INVENTARIO_REGISTRAR });
    dispatch({ type: RESET_PRODUCTO_DETALLES });
    navigate(`/ajuste-inventario/${id}`);
  };

  // Funcion para borrar el producto
  const manejarBorrarProducto = (e, id) => {
    e.stopPropagation();
    toast.dismiss();
    toast((t) => <ConfirmarBorrarObjeto id={id} t={t} objeto={"producto"} />, {
      duration: 5000,
    });
  };

  // Renderizar loading si se estan cargando los productos
  if (loading)
    return (
      <Loader />
    );

  // Renderizar mensaje de errors si el servidor regresa un error al pedir la lista de productos
  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de productos
        </Mensaje>
      </StyledContainer>
    );

  // Si se obtienen los productos renderizar la tabla de productos
  return (
    productos && (
      <>
        <StyledContainer fluid>
          <h1>Productos</h1>
          <StyledRow>
            <StyledCol>
              {/* Tabla de productos */}
              <TablaProductos
                productos={productos}
                manejarMostrarDetallesProducto={manejarMostrarDetallesProducto}
                manejarProductoDetalles={manejarProductoDetalles}
                manejarRealizarAjusteInventario={
                  manejarRealizarAjusteInventario
                }
                manejarBorrarProducto={manejarBorrarProducto}
              />
            </StyledCol>
          </StyledRow>
        </StyledContainer>

        {/* Mostrar ventana con detalles del producto */}
        {mostrarProducto && (
          <VentanaMostrarProducto
            producto={producto}
            mostrarProducto={mostrarProducto}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default ProductosLista;
