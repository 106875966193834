import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { registrarVenta } from "../../actions/ventaActions";
import {
  StyledButtonCancelar,
  StyledButtonConfirmar,
  StyledCol,
  StyledRow,
} from "./styles/VentanaMostrarVenta.styles";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import TicketVenta from "./TicketVenta";
import { RESET_VENTA_REGISTRAR } from "../../constantes/ventaConstantes";

const VentanaMostrarVenta = ({
  venta,
  mostrarVenta,
  setMostrarVenta,
  productosVenta,
  cliente,
}) => {
  // Funcion para disparar acciones
  const dispatch = useDispatch();
  // Funcion para navegar en la aplicacion
  const navigate = useNavigate();

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  const ventaRegistrar = useSelector((state) => state.ventaRegistrar);
  const {
    loading: loadingRegistrar,
    venta: ventaRedux,
    error: errorRegistrar,
  } = ventaRegistrar;

  // Obtener fecha y hora de la venta
  const { date, hour } = getCurrentDate();

  // Obtener monto sin descuento de la venta
  const monto = venta.MONTO;

  // Obtener porcentajeDescuento de la VENTA
  const porcentajeDescuento = venta.DESCUENTO;

  let montoSinDescuento = (
    venta.MONTO /
    (1 - porcentajeDescuento / 100)
  ).toFixed(2);

  if (isNaN(montoSinDescuento) || !isFinite(montoSinDescuento)) {
    montoSinDescuento = monto;
  }

  // Hook para calcular y mostrar el cambio de la venta
  const [cambio, setCambio] = useState(0);

  //Referencias de los botones
  const confirmarRef = useRef(null);
  const cancelarRef = useRef(null);
  const componentRef = useRef(null);

  // useEffect para mostrar las alertas de la venta
  useEffect(() => {
    let toastId;
    if (loadingRegistrar) {
      toast.loading("Realizando venta");
    }

    if (ventaRedux) {
      toast.dismiss(toastId);
      toast.success("Venta realizada");
    }

    if (errorRegistrar) {
      toast.dismiss(toastId);
      toast.error("Error al realizar venta");
    }
  }, [ventaRedux, errorRegistrar, loadingRegistrar, dispatch, navigate]);

  // Funcion para realizar la venta
  const manejarRealizarVenta = (e) => {
    e.preventDefault();
    setDisabledState(!disabledState);

    if (cambio < 0) {
      toast.error("El cambio debe ser mayor o igual a cero");
      setDisabledState(false)
    } else {
      // Disparar la accion para registrar la venta
      dispatch(registrarVenta(venta));
    }
  };

  // Funcion para realizar la impresion del ticket
  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      dispatch({ type: RESET_VENTA_REGISTRAR });
      setMostrarVenta(false);
      navigate("/ventas");
    },
  });

  // Cuando cambia el estado de ventaRedux ejecutar manejarPrint
  useEffect(() => {
    if (ventaRedux) {
      // Trigger print after venta data is loaded
      manejarPrint();
    }
  }, [ventaRedux, manejarPrint]);

  return (
    venta && (
      <>
        <Modal
          centered
          show={mostrarVenta}
          onHide={() => setMostrarVenta(false)}
        >
          <Modal.Header>
            <h4>Detalles de Venta</h4>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>VENDEDOR:</strong> {venta.VENDEDOR}
            </p>
            <p>
              <strong>CLIENTE:</strong> {cliente.nombre}
            </p>
            <p>
              <strong>FECHA:</strong> {date}
            </p>
            <p>
              <strong>HORA:</strong> {hour}
            </p>
            <p>
              <strong>MONTO SIN DESCUENTO:</strong> $ {montoSinDescuento}
            </p>
            <p>
              <strong>DESCUENTO:</strong> {porcentajeDescuento.toFixed(2)}%
            </p>
            <p>
              <strong>MONTO CON DESCUENTO:</strong> $ {monto.toFixed(2)}
            </p>

            <StyledRow>
              <StyledCol xs={4}>
                <Form onSubmit={manejarRealizarVenta}>
                  <Form.Group controlId="recibido">
                    <Form.Control
                      required={monto > 0}
                      type="number"
                      placeholder="Recibido"
                      onChange={(e) => setCambio(e.target.value - monto)}
                      step="any"
                    ></Form.Control>
                  </Form.Group>
                <div>
                  <StyledButtonConfirmar
                    color="green"
                    disabled={disabledState}
                    ref={confirmarRef}
                    type="submit"
                    onFocus={() => confirmarRef.current.blur()}
                  >
                    Confirmar
                  </StyledButtonConfirmar>
                </div>
                </Form>
              </StyledCol>
              <Col xs={6}>
                <p>Cambio: {cambio.toFixed(2)} $</p>
              </Col>
            </StyledRow>
            <p>
              <strong>TIPO DE VENTA:</strong> {venta.TIPO_VENTA}
            </p>
            <p>
              <strong>TIPO DE PAGO:</strong> {venta.TIPO_PAGO}
            </p>
            <p>
              <strong>STATUS:</strong> {venta.STATUS}
            </p>
            <p>
              <strong>OBSERVACIONES:</strong> {venta.OBSERVACIONES}
            </p>
            <p>
              <strong>PRODUCTOS DE LA VENTA:</strong>
            </p>
            <ul>
              {productosVenta.map((pv) => (
                <li key={pv.id}>
                  {pv.producto_nombre} x {pv.cantidadVenta} = ${" "}
                  {(pv.PRECIO * (1 - porcentajeDescuento / 100)).toFixed(2)}
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <StyledButtonCancelar
              color="red"
              variant="danger"
              onClick={() => setMostrarVenta(false)}
              ref={cancelarRef}
              onFocus={() => cancelarRef.current.blur()}
            >
              Cancelar
            </StyledButtonCancelar>
          </Modal.Footer>
        </Modal>
        <div style={{ display: "none" }}>
          <TicketVenta datosVenta={ventaRedux} ref={componentRef} />
        </div>
      </>
    )
  );
};

const getCurrentDate = () => {
  const currentDate = new Date();

  const date = currentDate.toLocaleDateString();
  const hour = currentDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { date, hour };
};

export default VentanaMostrarVenta;
