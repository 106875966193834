export const crearProductosVentaConSubtotal = (productosVenta) => {
  const nuevosProductosVenta = productosVenta.map((pv) => {
    const productoId = pv.PRODUCTO;

    const cantidadVenta = pv.cantidadVenta;

    const precioVenta = pv.PRECIO * pv.cantidadVenta;

    return { productoId, cantidadVenta, precioVenta };
  });

  return nuevosProductosVenta;
};

export const calcularMontoTotal = (tipoPago, nuevosProductosVenta) => {
  if (tipoPago === "CORTESIA") {
    return 0;
  } else {
    const monto = nuevosProductosVenta.reduce(
      (total, pv) => pv.precioVenta + total,
      0
    );

    return monto;
  }
};

export const modifyJSON = (data) => {
  let newData = [];

  // Iterate through each object in the original array
  for (let i = 0; i < data.length; i++) {
    let obj = {};

    // Iterate through each property in the object
    for (let prop in data[i]) {
      // Check if the property is one that needs to be modified

      // 1.
      if (prop === "NOMBRE_CLIENTE") {
        obj["CLIENTE"] = data[i][prop];
      } else if (prop === "PRODUCTO_NOMBRE") {
        obj["PRODUCTO"] = data[i][prop];
      } else if (prop === "TIPO_AJUSTE") {
        obj["TIPO DE AJUSTE"] = data[i][prop];
      }
      // 2. Create two new columns from the column with the column name "FECHA"
      else if (prop === "FECHA") {
        // Convert the date from UTC to local time
        const localDate = new Date(data[i][prop]);

        // Format the date as "DD/MM/YYYY"
        const formattedDate = localDate.toLocaleDateString("es-ES"); // Cambia 'es-ES' a tu preferencia de localización

        // Format the time as "HH:mm"
        const formattedTime = localDate.toLocaleTimeString("es-ES", {
          hour: "2-digit",
          minute: "2-digit",
        });

        // Create the new columns
        obj["FECHA"] = formattedDate;
        obj["HORA"] = formattedTime;
      }
      // 3. Change the column name "TIPO_VENTA" to "TIPO DE VENTA"
      else if (prop === "TIPO_VENTA") {
        obj["TIPO DE VENTA"] = data[i][prop];
      }
      // 4. Change the name "TIPO_PAGO" to "TIPO DE PAGO"
      else if (prop === "TIPO_PAGO") {
        obj["TIPO DE PAGO"] = data[i][prop];
      }
      // Add all other properties to the new object, except "productos_venta" and "CLIENTE"
      else if (prop !== "productos_venta" && prop !== "CLIENTE") {
        obj[prop] = data[i][prop];
      }
    }

    // Add the modified object to the new array
    newData.push(obj);
  }

  return newData;
};

export const obtenerFechaActual = () => {
  const date = new Date();
  let dia = date.getDate();
  dia = String(dia).padStart(2, "0");
  let mes = date.getMonth() + 1;
  mes = String(mes).padStart(2, "0");
  const anio = date.getFullYear();

  // This arrangement can be altered based on how we want the date's format to appear.
  return `${anio}-${mes}-${dia}`;
};

export const getFormatoFecha = (date) => {
  const formattedDate = new Date(date);

  // Extracting fecha (date)
  const fecha = `${formatearNumeroConCero(formattedDate.getDate())}/${
    mesesAbreviados[formattedDate.getMonth()]
  }/${formattedDate.getFullYear()}`;

  return fecha;
};

export const getFormatoHora = (date) => {
  const formattedDate = new Date(date);

  const hora = `${formatearNumeroConCero(
    formattedDate.getHours()
  )}:${formatearNumeroConCero(formattedDate.getMinutes())}`;

  return hora;
};

export const obtenerValoresFiltroVentas = () => {
  const buscar = JSON.parse(localStorage.getItem("buscar") || '""');
  const filtrarPor = JSON.parse(
    localStorage.getItem("filtrarPor") || '"nombre_cliente"'
  );
  const ordenarPor = JSON.parse(
    localStorage.getItem("ordenarPor") || '"fecha_recientes"'
  );
  const fechaInicio = JSON.parse(localStorage.getItem("fechaInicio") || '""');
  const fechaFinal = JSON.parse(
    localStorage.getItem("fechaFinal") || `"${obtenerFechaActual()}"`
  );

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
  };
};

export const guardarFiltrosVenta = (
  buscar,
  filtrarPor,
  ordenarPor,
  fechaInicio,
  fechaFinal
) => {
  localStorage.setItem("buscar", JSON.stringify(buscar));
  localStorage.setItem("filtrarPor", JSON.stringify(filtrarPor));
  localStorage.setItem("ordenarPor", JSON.stringify(ordenarPor));
  localStorage.setItem("fechaInicio", JSON.stringify(fechaInicio));
  localStorage.setItem("fechaFinal", JSON.stringify(fechaFinal));
};

// Ticket venta

export const totalizarCantidadProductos = (productos) =>
  productos.reduce((total, producto) => total + producto.CANTIDAD_VENTA, 0);

export const sumarPreciosProductos = (productos) =>
  productos.reduce((subtotal, producto) => subtotal + producto.PRECIO_VENTA, 0);

export const informacionTienda = (ciudad) => {  
  return {
    "LOCAL": {
      "CALLE": ciudad === "URUAPAN" ? "Culiver City":"Morelos",
      "NUMERO": ciudad === "URUAPAN" ? "3":"20",
      "COLONIA": ciudad === "URUAPAN" ? "Barrio de Santo Santiago":"Ejidal",
      "MUNICIPIO": ciudad === "URUAPAN" ? "Uruapan":"Lázaro Cárdenas",
      "ESTADO": "Mich",
      "CP": ciudad === "URUAPAN" ? "60030":"60953",
      "RFC": "OIGA7111294F1",
    },
  }
};

export const mesesAbreviados = {
  0: "Ene",
  1: "Feb",
  2: "Mar",
  3: "Abr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Ago",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dic",
};

export const formatearNumeroConCero = (factor) =>
  factor.toString().padStart(2, "0");

export const obtenerFechaActualConFormato = () => {
  const fecha = new Date();

  return `${formatearNumeroConCero(fecha.getDate())}/${
    mesesAbreviados[fecha.getMonth()]
  }/${fecha.getFullYear()} ${formatearNumeroConCero(
    fecha.getHours()
  )}:${formatearNumeroConCero(fecha.getMinutes())}`;
};
