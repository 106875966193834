// Pedir lista de productos
export const REQUEST_PRODUCTO_LISTA = "REQUEST_PRODUCTO_LISTA";
export const SUCCESS_PRODUCTO_LISTA = "SUCCESS_PRODUCTO_LISTA";
export const FAIL_PRODUCTO_LISTA = "FAIL_PRODUCTO_LISTA";
export const RESET_PRODUCTO_LISTA = "RESET_PRODUCTO_LISTA";
export const ABORT_PRODUCTO_LISTA = "ABORT_PRODUCTO_LISTA";
// Pedir un solo producto
export const REQUEST_PRODUCTO_DETALLES = "REQUEST_PRODUCTO_DETALLES";
export const SUCCESS_PRODUCTO_DETALLES = "SUCCESS_PRODUCTO_DETALLES";
export const FAIL_PRODUCTO_DETALLES = "FAIL_PRODUCTO_DETALLES";
export const RESET_PRODUCTO_DETALLES = "RESET_PRODUCTO_DETALLES";

// Actualizar un producto
export const REQUEST_PRODUCTO_ACTUALIZAR = "REQUEST_PRODUCTO_ACTUALIZAR";
export const SUCCESS_PRODUCTO_ACTUALIZAR = "SUCCESS_PRODUCTO_ACTUALIZAR";
export const FAIL_PRODUCTO_ACTUALIZAR = "FAIL_PRODUCTO_ACTUALIZAR";
export const RESET_PRODUCTO_ACTUALIZAR = "RESET_PRODUCTO_ACTUALIZAR";

// Registrar un nuevo producto
export const REQUEST_PRODUCTO_REGISTRAR = "REQUEST_PRODUCTO_REGISTRAR";
export const SUCCESS_PRODUCTO_REGISTRAR = "SUCCESS_PRODUCTO_REGISTRAR";
export const FAIL_PRODUCTO_REGISTRAR = "FAIL_PRODUCTO_REGISTRAR";
export const RESET_PRODUCTO_REGISTRAR = "RESET_PRODUCTO_REGISTRAR";

// Borrar un producto
export const REQUEST_PRODUCTO_BORRAR = "REQUEST_PRODUCTO_BORRAR";
export const SUCCESS_PRODUCTO_BORRAR = "SUCCESS_PRODUCTO_BORRAR";
export const FAIL_PRODUCTO_BORRAR = "FAIL_PRODUCTO_BORRAR";
export const RESET_PRODUCTO_BORRAR = "RESET_PRODUCTO_BORRAR";
