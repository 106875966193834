import { useState } from "react";
import { toast } from "react-hot-toast";

export const useProductosVenta = (
  productosCliente,
  manejarCambiarProductosCliente
) => {
  const [productosVenta, setProductosVenta] = useState([]);

  const manejarLimpiarProductosVenta = () => setProductosVenta([]);

  const manejarSeleccionarProducto = (productoId) => {
    const productoSeleccionado = productosCliente.find(
      (p) => p.id === productoId
    );

    // Cuando se selecciona un producto para la venta se debe establer su cantidad inicial a 0 y su status de confirmado a false
    const productoActualizado = {
      ...productoSeleccionado,
      confirmado: false,
      cantidadVenta: 0,
    };

    // Remover el producto seleccionado de productos cliente
    const nuevosProductosCliente = productosCliente.filter(
      (p) => p.id !== productoId
    );
    manejarCambiarProductosCliente(nuevosProductosCliente);

    // Agregar producto seleccionado a productos venta
    const nuevosProductosVenta = [productoActualizado, ...productosVenta];
    setProductosVenta(nuevosProductosVenta);
  };

  const manejarCambioCantidad = (e, nuevaCantidad, productoId) => {
    // Es stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    toast.dismiss()

    // Obtener el producto al que hay que modificar la cantidad
    const productoSeleccionado = productosVenta.find(
      (pv) => pv.id === productoId
    );

    // Obtener la cantidad disponible de ese producto
    const cantidadDisponible = productoSeleccionado.producto_cantidad;

    // Verificar si la nueva cantidad excede la cantidad existente de producto
    if (nuevaCantidad > cantidadDisponible) {
      toast.error(
        `La cantidad seleccionada debe ser inferior o igual a ${cantidadDisponible}`,
        { duration: 4000 }
      );

      // Si la nueva cantidad excede la cantidad existende hacer la nuevaCantidad igual a la cantidad existente
      nuevaCantidad = cantidadDisponible;
    } else if (nuevaCantidad <= 0) {
      // React does not like it when a controlled input's value becomes null. If you'd like to clear the input, consider using an empty string "" instead. However, this can cause a problem if you need to differentiate between an empty input and a 0.

      // This way, you avoid setting a controlled input value to null, which React advises against, and you're also not setting it to 0, so the user can clear the input.
      nuevaCantidad = "";
    }

    //// Actualizar el precio del producto

    // Crear una copia del arreglo de productos
    const nuevosProductosVenta = [...productosVenta];

    // Encontrar la posicion del producto a actualizar
    const indexProducto = productosVenta.findIndex(
      (producto) => producto.id === productoId
    );

    // Actualizar los productos de venta
    nuevosProductosVenta[indexProducto] = {
      ...productosVenta[indexProducto],
      cantidadVenta: nuevaCantidad,
    };
    setProductosVenta(nuevosProductosVenta);
  };

  const manejarConfirmarProducto = (e, productoId) => {
    // Es stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    // Encontrar el producto seleccionado y cambiar su status
    const nuevosProductosVenta = productosVenta.map((p) => {
      if (p.id === productoId) {
        p.confirmado = !p.confirmado;
      }
      return p;
    });

    setProductosVenta(nuevosProductosVenta);
  };

  const manejarCancelarProducto = (e, productoId) => {
    // Es stopPropagation evita que se muestre el modal con la infomacion del producto
    e.stopPropagation();

    // Crear copia del producto seleccionado
    const productoSeleccionado = {
      ...productosVenta.find((p) => p.id === productoId),
    };

    // Remover producto de productos de venta
    const nuevosProductosVenta = productosVenta.filter(
      (p) => p.id !== productoId
    );
    setProductosVenta(nuevosProductosVenta);

    // Agregar producto a productos disponibles del cliente
    const nuevosProductosCliente = [productoSeleccionado, ...productosCliente];
    manejarCambiarProductosCliente(nuevosProductosCliente);
  };

  // Esta funcion se evalua siempre que cambia productosVentas
  const isValid = getIsValid(productosVenta);

  return {
    productosVenta,
    manejarLimpiarProductosVenta,
    manejarSeleccionarProducto,
    manejarCambioCantidad,
    manejarConfirmarProducto,
    manejarCancelarProducto,

    isValid,
  };
};

const getIsValid = (productosVenta) => {
  return (
    productosVenta.length > 0 &&
    productosVenta.every((p) => p.confirmado) &&
    productosVenta.every((p) => p.cantidadVenta > 0)
  );
};
