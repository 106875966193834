import React from "react";
import { StyledBoxShadow, StyledContainer, StyledH1 } from "./styles/Error404.styles";

export const Error404 = () => {
    return(
        <StyledContainer>
            <StyledBoxShadow/>
            <StyledH1>
                Ups, buscamos por todas partes, pero la pagina solicitada no fue encontrada.
            </StyledH1>
        </StyledContainer>
    )
}