import { Modal } from "react-bootstrap";
import {
  StyledBotonPrint,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  TablaEstilo,
} from "./styles/VentanaMostrarVenta.styles";
import { StyledButton } from "../general/styles/Boton.styles";

import { getFormatoFecha, getFormatoHora } from "../../lib/utilis/venta";
import { useRef } from "react";
import TicketVenta from "../RealizarVenta/TicketVenta";
import { useReactToPrint } from "react-to-print";

const VentanaMostrarModalVenta = ({
  venta,
  mostrarVenta,
  manejarCerrarVentana,
}) => {
  const {
    FOLIO,
    productos_venta,
    DESCUENTO,
    MONTO,
    STATUS,
    OBSERVACIONES,
    NOMBRE_CLIENTE,
    FECHA,
    VENDEDOR,
    TIPO_VENTA,
    TIPO_PAGO,
  } = venta;

  //Obtener referencia del boton
  const cerrarRef = useRef(null);
  const componentRef = useRef(null);

  // Funcion para realizar la impresion del ticket
  const manejarPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    venta &&
    productos_venta && (
      <>
        <Modal
          scrollable
          centered
          show={mostrarVenta}
          onHide={manejarCerrarVentana}>
          <StyledModalHeader>
            <h4>Detalle de la Venta #{FOLIO}</h4>
          </StyledModalHeader>

          <StyledModalBody>
            <h5>DATOS GENERALES:</h5>
            <h5>Cliente:</h5> <p>{NOMBRE_CLIENTE}</p>
            <h5>Fecha de Venta:</h5>
            <p>
              {getFormatoFecha(FECHA)} --
              <span> {getFormatoHora(FECHA)}</span>
            </p>
            <h5>Vendedor:</h5> <p>{VENDEDOR}</p>
            <h5>Tipo de Venta:</h5> <p>{TIPO_VENTA}</p>
            <h5>Tipo de Pago:</h5> <p>{TIPO_PAGO}</p>
            <h5>Tabla de Productos:</h5>
            <TablaEstilo>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Precio al cliente</th>
                </tr>
              </thead>
              <tbody>
                {productos_venta.map((producto, index) => (
                  <tr key={index}>
                    <td>{producto.NOMBRE_PRODUCTO}</td>
                    <td>{producto.CANTIDAD_VENTA}</td>
                    <td>
                      $
                      {(
                        producto.PRECIO_VENTA / producto.CANTIDAD_VENTA
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TablaEstilo>
            <h5>Porcentaje de descuento:</h5> <p>{DESCUENTO.toFixed(3)}%</p>
            <h5>Monto total:</h5>
            <p>${MONTO.toFixed(2)}</p>
            <h5>Status:</h5>
            <p>{STATUS}</p>
            <h5>Observaciones:</h5>
            <p>{OBSERVACIONES}</p>
          </StyledModalBody>

          <StyledModalFooter>
            <StyledBotonPrint color="green" onClick={manejarPrint}>
              Reimprimir ticket
            </StyledBotonPrint>
            <StyledButton
              color="blue"
              disabled={false}
              ref={cerrarRef}
              onFocus={() => cerrarRef.current.blur()}
              variant="secondary"
              onClick={() => {
                manejarCerrarVentana();
              }}>
              Cerrar
            </StyledButton>
          </StyledModalFooter>
        </Modal>
        <div style={{ display: "none" }}>
          <TicketVenta datosVenta={venta} ref={componentRef} />
        </div>
      </>
    )
  );
};

export default VentanaMostrarModalVenta;
