import { useState } from "react";

export const useFiltroClientesVenta = () => {
  const [filtroClientes, setFiltroClientes] = useState({
    searchQuery: "",
    searchQuerMyessage:
      "Introduce al menos tres letras para iniciar la búsqueda del cliente",
  });

  const manejarSearchQuery = (newSearchQuery) => {
    if (newSearchQuery.length < 3) {
      setFiltroClientes({
        searchQuery: newSearchQuery,
        searchQueryMessage:
          "Introduce al menos tres letras para iniciar la búsqueda del cliente",
      });
      return;
    }

    setFiltroClientes({
      searchQuery: newSearchQuery,
      searchQueryMessage: "Buscando coincidencias...",
    });
  };

  return {
    ...filtroClientes,
    setFiltroClientes,
    manejarSearchQuery,
  };
};
