import { useState } from "react";

// Funcion para mostrar ventana con detalles del producto
export const useMostrarDetallesAjusteInventario = (ajusteInventarios = []) => {
  // Estado para la ventana emergente con la informacion del producto
  const [mostrarModalAjusteInventario, setMostrarModalAjusteInventario] =
    useState(false);
  // Estado para guardar informacion del producto
  const [ajusteInventario, setAjusteInventario] = useState({});

  // Funcion para cerrar la ventana de informacion del producto
  const manejarCerrarVentana = () => {
    setMostrarModalAjusteInventario(false);
  };

  // Funcion para mostrar ventana emergente con detalles del producto
  const manejarMostrarDetallesAjusteInventario = (ajusteInventarioId) => {
    const ajusteInventarioSeleccionado = {
      ...ajusteInventarios.find((ai) => ai.id === ajusteInventarioId),
    };
    setAjusteInventario(ajusteInventarioSeleccionado);
    setMostrarModalAjusteInventario(true);
  };

  return {
    ajusteInventario,
    mostrarModalAjusteInventario,
    manejarMostrarDetallesAjusteInventario,
    manejarCerrarVentana,
  };
};
