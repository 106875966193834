import React, { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { obtenerProductoDetalles } from "../actions/productoActions";
import { RESET_PRODUCTO_DETALLES } from "../constantes/productoConstantes";
import Loader from "../componentes/general/Loader";
import Mensaje from "../componentes/general/Mensaje";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/ProductoDetalles.styles";
import { registrarAjustarInventario } from "../actions/ajusteInventario";
import { RESET_AJUSTE_INVENTARIO_REGISTRAR } from "../constantes/ajusteInventarioConstantes";
import { useForm } from "react-hook-form";
import { useRef } from "react";

const RealizarAjusteInventario = () => {
  // Obtener el id del producto
  const params = useParams();
  const productoId = params.id;

  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Estado deshabilitacion de boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener el estado desde el Redux store
  const productoDetalles = useSelector((state) => state.productoDetalles);
  const { loading, producto, error } = productoDetalles;

  //Obtener referencia de los botones
  const regresarRef = useRef(null);
  const ajustarRef = useRef(null);

  // Obtener el estado desde el Redux store
  const ajusteInventarioRegistrar = useSelector(
    (state) => state.ajusteInventarioRegistrar
  );
  const {
    loading: ajusteInventarioLoading,
    success: ajusteInventarioSuccess,
    error: ajusteInventarioError,
  } = ajusteInventarioRegistrar;

  const [cantidadInicial, setCantidadInicial] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // useEffect para mostrar las alertas de actualizar producto
  useEffect(() => {
    let toastId;
    if (ajusteInventarioLoading) {
      toastId = toast.loading("Actualizando producto");
    }

    if (ajusteInventarioSuccess) {
      toast.dismiss(toastId);
      toast.success("Producto actualizado");
      // Reset producto actualizar para que no se ejecute este bloque de codigo cada vez que se entra a producto detalles
      dispatch({ type: RESET_AJUSTE_INVENTARIO_REGISTRAR });
      navigate("/productos");
    }

    if (ajusteInventarioError) {
      toast.dismiss(toastId);
      toast.error("Error al actualizar");
    }
  }, [
    ajusteInventarioSuccess,
    ajusteInventarioError,
    ajusteInventarioLoading,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // useEffect para mostrar la validacion del formulario
  useEffect(() => {
    if (errors.observaciones) {
      toast.dismiss();
      toast.error(errors.observaciones.message);
    }

    if (errors.tipoAjuste) {
      toast.dismiss();
      toast.error(errors.tipoAjuste.message);
    }

    if (errors.cantidad) {
      toast.dismiss();
      toast.error(errors.cantidad.message);
    }

    if (errors.bodega) {
      toast.dismiss();
      toast.error(errors.bodega.message);
    }
  }, [errors.bodega, errors.cantidad, errors.tipoAjuste, errors.observaciones]);

  useEffect(() => {
    // Si no hay producto o el producto no es el que seleccione, disparar la accion de obtener producto
    if (!producto || producto.id !== Number(productoId)) {
      dispatch(obtenerProductoDetalles(productoId));
    } else {
      setCantidadInicial(producto.CANTIDAD);
    }
  }, [dispatch, producto, productoId, ajusteInventarioSuccess, navigate]);

  const manejarActualizarProducto = (data) => {
    // Disparar la accion de actualizar producto
    setDisabledState(!disabledState);

    const { bodega, tipoAjuste, cantidad, observaciones } = data;

    if (tipoAjuste === "FALTANTE" && cantidad > cantidadInicial) {
      toast.dismiss();
      toast.error(
        "No hay suficiente cantidad en el inventario para este ajuste."
      );
    } else if (cantidad <= 0) {
      toast.dismiss();
      toast.error("La cantidad seleccionada debe ser mayor a 0.");
    } else {
      const ajusteInventario = {
        CAJERO: CAJERO,
        BODEGA: bodega,
        PRODUCTO: Number(productoId),
        PRODUCTO_NOMBRE: producto.NOMBRE,
        CANTIDAD: Number(cantidad),
        TIPO_AJUSTE: tipoAjuste ? tipoAjuste : "PRODUCCION",
        OBSERVACIONES: observaciones,
        CIUDAD_REGISTRO: producto.CIUDAD_REGISTRO,
      };

      dispatch(registrarAjustarInventario(ajusteInventario));
    }
  };

  const manejarRegresar = () => {
    // Redireccionar a la pagina de productos
    dispatch({ type: RESET_PRODUCTO_DETALLES });
    navigate("/productos");
  };

  const CAJERO = JSON.parse(localStorage.getItem("name"));
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

  // Renderizar loading si se estan cargando la informacion del producto
  if (loading) return <Loader />;

  // Renderizar mensaje de errors si el servidor regresa un error al pedir la informacion del producto
  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow style={{ height: "80%" }}>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un error al cargar la informacion del producto
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  if (producto)
    return (
      <StyledContainer fluid>
        <h1>Producto #{producto.id}</h1>
        <Row>
          <StyledCol>
            <StyledBoton
              color="green"
              ref={regresarRef}
              disabled={false}
              onFocus={() => regresarRef.current.blur()}
              onClick={manejarRegresar}>
              Regresar
            </StyledBoton>
          </StyledCol>
        </Row>
        <Form onSubmit={handleSubmit(manejarActualizarProducto)}>
          <StyledRow>
            <StyledCol md={6}>
              <StyledFormGroup controlId="bodega">
                <Form.Label> Nombre del tanquero</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  {...register("bodega", {
                    required: "Por favor, introduce el nombre del tanquero",
                  })}></Form.Control>
              </StyledFormGroup>

              {isAdmin && (
                <>
                  <StyledFormGroup controlId="cantidad">
                    <Form.Label style={{ color: "white", fontWeight: "bold" }}>
                      Tipo de Ajuste
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black", fontWeight: "bold" }}
                      as="select"
                      {...register("tipoAjuste")}>
                      <option value="PRODUCCION">PRODUCCION</option>
                      <option value="FALTANTE">FALTANTE</option>
                      <option value="SOBRANTE">SOBRANTE</option>
                    </Form.Control>
                  </StyledFormGroup>
                </>
              )}
            </StyledCol>
            <StyledCol md={6}>
              <StyledFormGroup controlId="cantidad">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control
                  type="number"
                  {...register("cantidad", {
                    required: "Por favor, introduce la cantidad",
                  })}
                  step="any"></Form.Control>
                <p style={{ color: "white" }}>
                  Cantidad de producto en stock:{" "}
                  {cantidadInicial && cantidadInicial.toFixed(2)}
                </p>
              </StyledFormGroup>

              <StyledFormGroup controlId="observaciones">
                <Form.Label> Observaciones</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  {...register("observaciones", {
                    required: "Por favor, introduce las observaciones",
                  })}></Form.Control>
              </StyledFormGroup>
            </StyledCol>
          </StyledRow>
          <Row>
            <StyledCol>
              <StyledBoton
                type="submit"
                color="green"
                ref={ajustarRef}
                disabled={disabledState}
                onFocus={() => ajustarRef.current.blur()}>
                Realizar ajuste de inventario
              </StyledBoton>
            </StyledCol>
          </Row>
        </Form>
      </StyledContainer>
    );
};

export default RealizarAjusteInventario;
