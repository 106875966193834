import { obtenerFechaActual } from "./venta";

export const obtenerValoresFiltroAjusteInventario = () => {
  const buscar = JSON.parse(localStorage.getItem("ajusteBuscar") || '""');
  const filtrarPor = JSON.parse(
    localStorage.getItem("ajusteFiltrarPor") || '"cajero"'
  );
  const ordenarPor = JSON.parse(
    localStorage.getItem("ajusteOrdenarPor") || '"fecha_recientes"'
  );
  const fechaInicio = JSON.parse(
    localStorage.getItem("ajusteFechaInicio") || '""'
  );
  const fechaFinal = JSON.parse(
    localStorage.getItem("ajusteFechaFinal") || `"${obtenerFechaActual()}"`
  );

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
  };
};

export const guardarFiltrosAjusteInventario = (
  buscar,
  filtrarPor,
  ordenarPor,
  fechaInicio,
  fechaFinal
) => {
  localStorage.setItem("ajusteBuscar", JSON.stringify(buscar));
  localStorage.setItem("ajusteFiltrarPor", JSON.stringify(filtrarPor));
  localStorage.setItem("ajusteOrdenarPor", JSON.stringify(ordenarPor));
  localStorage.setItem("ajusteFechaInicio", JSON.stringify(fechaInicio));
  localStorage.setItem("ajusteFechaFinal", JSON.stringify(fechaFinal));
};
