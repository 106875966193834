import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Loader from "../componentes/general/Loader";
import VentanaMostrarCliente from "../componentes/ClientesLista/VentanaMostrarCliente";
import {
  RESET_CLIENTE_BORRAR,
  RESET_CLIENTE_DETALLES,
} from "../constantes/clienteConstantes";
import ConfirmarBorrarObjeto from "../componentes/general/ConfirmarBorrarObjeto";
import Mensaje from "../componentes/general/Mensaje";
import FiltroListaClientes from "../componentes/ClientesLista/FiltroListaClientes";
import TablaClientes from "../componentes/ClientesLista/TablaClientes";
import {
  StyledBoton,
  StyledBotonContainer,
  StyledBotonPanel,
  StyledContainer,
  StyledContenidoPrincipal,
  StyledGridContainer,
  StyledPanelControl,
  StyledTitulo,
} from "./styles/ClientesLista.styles";

import PaginacionClientes from "../componentes/ClientesLista/PaginacionClientes";
import { useMostrarDetallesCliente } from "../lib/hooks/useMostrarDetallesCliente";
import { pedirClientesLista } from "../actions/clienteActions";
import { useRef } from "react";
import axios from "axios";

const ClientesLista = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();
  // Funcion para nevagar en la aplicacion
  const navigate = useNavigate();
  // Funcion para obtener el search param en el url
  const location = useLocation();
  const search = location.search;

  // Obtener lista de clientes desde el Redux store
  const clienteLista = useSelector((state) => state.clienteLista);
  const { loading, clientes, error, page, pages } = clienteLista;

  //Obtener referencias de botones
  const descargarRef = useRef(null);
  const resumenRef = useRef(null);
  const panelMovilRef = useRef(null);

  // Obtener el estado borrar cliente desde el Redux sotore
  const clienteBorrar = useSelector((state) => state.clienteBorrar);
  const {
    loading: borrarLoading,
    success: borrarSuccess,
    error: borrarError,
  } = clienteBorrar;

  // Hook para mostrar y ocultar panel de control en pantallas pequenas
  const [mostrarPanel, setMostrarPanel] = useState(true);

  // Custom Hook para mostrar ventana con detalles del cliente
  const {
    mostrarCliente,
    cliente,
    manejarCerrarVentana,
    manejarMostrarDetallesCliente,
  } = useMostrarDetallesCliente(clientes);

  // Si el parametro search cambia pedir la lista de clientes
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (!borrarSuccess) {
      dispatch(pedirClientesLista(search, source.token));
    }

    return () => {
      source.cancel("Petición lista clientes cancelada");
    };
  }, [dispatch, search, borrarSuccess]);

  // useEffect para mostrar alertas de eliminar cliente
  useEffect(() => {
    let toastId;
    if (borrarLoading) {
      toastId = toast.loading("Eliminando cliente");
    }

    if (borrarSuccess) {
      toast.dismiss(toastId);
      toast.success("Cliente eliminado exitosamente", {
        duration: 2000,
      });
      // Reset cliente borrar para que no se ejecute este bloque de codigo cada vez que se entra a la lista de clientes
      dispatch({ type: RESET_CLIENTE_BORRAR });
    }

    if (borrarError) {
      toast.dismiss(toastId);
      toast.error("Error al eliminar cliente", {
        duration: 4000,
      });
    }
  }, [borrarSuccess, borrarError, borrarLoading, dispatch]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // Funcion para redireccionar a la pagina del cliente
  const manejarClienteDetalles = (id) => {
    // Redireccionar a la pagina del cliente
    dispatch({ type: RESET_CLIENTE_DETALLES });
    navigate(`/clientes/${id}`);
  };

  // Funcion para eliminar cliente
  const manejarBorrarCliente = (e, id) => {
    toast.dismiss();
    e.stopPropagation();
    toast((t) => <ConfirmarBorrarObjeto id={id} t={t} objeto={"cliente"} />, {
      duration: 5000,
    });
  };

  // Renderizar loading si se estan cargando los clientes
  if (loading) return <Loader />;

  // Renderizar mensaje de error si el servidor regresa un error al pedir la lista de clientes
  if (error)
    return (
      <StyledContainer>
        <Mensaje variant="danger">
          Hubo un error al cargar la lista de clientes
        </Mensaje>
      </StyledContainer>
    );

  // Renderizar tabla de clientes
  return (
    clientes && (
      <>
        <StyledGridContainer>
          <StyledBotonPanel
            color="standard"
            fontcolor="var(--active-blue-button)"
            ref={panelMovilRef}
            disabled={false}
            onClick={() => setMostrarPanel((state) => !state)}
            onFocus={() => panelMovilRef.current.blur()}
            state={mostrarPanel}>
            <i className="fa-solid fa-arrow-right"></i>
          </StyledBotonPanel>
          <StyledTitulo>Clientes</StyledTitulo>
          {/* Panel de control para filtrar y ordenar clientes */}
          <StyledPanelControl mostrarPanel={mostrarPanel}>
            <FiltroListaClientes />

            {/* Exportar clientes */}
            {/* <StyledBotonContainer>
              <StyledBoton
                type="submit"
                color="green"
                ref={descargarRef}
                disabled={false}
                onFocus={() => descargarRef.current.blur()}
                // onClick={() => manejarExportarVentas(ventasFiltradas)}
              >
                Descargar tabla de clientes
              </StyledBoton>
            </StyledBotonContainer> */}

            {/* Mostrar resumen de clientes */}

            {/* <StyledBotonContainer>
              <StyledBoton
                type="submit"
                color="green"
                ref={resumenRef}
                disabled={false}
                onFocus={() => resumenRef.current.blur()}>
                Mostrar resumen de clientes
              </StyledBoton>
            </StyledBotonContainer> */}
          </StyledPanelControl>
          {/* Tabla de clientes */}
          <StyledContenidoPrincipal>
            <TablaClientes
              clientesFiltrados={clientes}
              manejarMostrarDetallesCliente={manejarMostrarDetallesCliente}
              manejarClienteDetalles={manejarClienteDetalles}
              manejarBorrarCliente={manejarBorrarCliente}></TablaClientes>
            <PaginacionClientes
              page={page}
              pages={pages}
              search={search}
              isAdmin={false}
            />
          </StyledContenidoPrincipal>
        </StyledGridContainer>
        {/* Mostrar venta con detalles del cliente */}
        {mostrarCliente && (
          <VentanaMostrarCliente
            cliente={cliente}
            mostrarCliente={mostrarCliente}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default ClientesLista;
