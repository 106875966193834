import styled from "styled-components";

export const StyledTexto = styled.p`
  font-weight: ${(props) => props.negrita};
  font-size: ${(props) => props.tamaño};
  color: black;
  text-align: ${(props) => props.alineacion};
  width: 100%;
  overflow-x: hidden;
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  position: relative;
  margin-bottom: ${(props) => props.bottom};
  margin-top: ${(props) => props.top};

  &::-webkit-scrollbar {
      display: none;
  }


  & span {
    display: inline-block;
    position: relative;
    top: ${(props) => props.topSpan};
    height: ${(props) => props.heightSpan};
    font-weight: ${(props) => props.spanFont};
    color: ${(props) => props.color};
  }
`;
