import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../actions/sesionActions";
import { toast } from "react-hot-toast";
import {
  ForgotPswdContainer,
  StyledBackground,
  StyledBar,
  StyledBoton,
  StyledContainer,
  StyledControl,
  StyledForm,
  StyledInput,
  StyledLabel,
  StyledLogoContainer,
  StyledSpan,
} from "./styles/InicioSesion.styles";
import { BASE_URL } from "../constantes/constantes";
import { useRef } from "react";

const InicioSesion = () => {
  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Obtener referencia del boton
  const loginRef = useRef(null);

  // Obtener la informacion del usuario desde el Redux store
  const usuarioInfo = useSelector((state) => state.usuarioInfo);
  const { loading, token, error } = usuarioInfo;

  // Hook para guardar las credenciales del usuario
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // useState para mostrar mensajes de inicio de sesion
  useEffect(() => {
    if (loading) {
      toast.loading("Iniciando sesión");
    }

    // Si el usuario ya ha iniciado sesion redirecciona a la pagina de inicio
    if (token) {
      toast.dismiss();
      toast.success("Se inició sesión correctamente", {
        duration: 2000,
      });
      navigate("/");
    }

    if (error) {
      toast.remove();
      toast.error("Por favor, introduce credenciales válidas", {
        duration: 4000,
      });
    }
  }, [navigate, token, error, loading]);

  // Funcion para iniciar sesion
  const manejarSubmit = (e) => {
    e.preventDefault();
    // Disparar el creador de acciones login
    dispatch(login(username, password));
  };

  return (
    <StyledBackground>
      <StyledContainer>
        <StyledLogoContainer>
          <Image
            src={`${BASE_URL}/static/imagenes/general/logo.png`}
            alt="Hielo Gran Pacifico Logo"
          />
        </StyledLogoContainer>
        <StyledForm autoComplete="off" onSubmit={manejarSubmit}>
          <StyledControl id="username">
            <StyledInput
              required=" "
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <StyledLabel>
              <i className="fa-solid fa-user" />
              <StyledSpan delay={"0ms"}>U</StyledSpan>
              <StyledSpan delay={"25ms"}>s</StyledSpan>
              <StyledSpan delay={"50ms"}>u</StyledSpan>
              <StyledSpan delay={"75ms"}>a</StyledSpan>
              <StyledSpan delay={"100ms"}>r</StyledSpan>
              <StyledSpan delay={"125ms"}>i</StyledSpan>
              <StyledSpan delay={"150ms"}>o</StyledSpan>
            </StyledLabel>
            <StyledBar className="bar" />
          </StyledControl>

          <StyledControl id="password">
            <StyledInput
              required=" "
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <StyledLabel>
              <i className="fa-solid fa-lock" />
              <StyledSpan delay={"0ms"}>C</StyledSpan>
              <StyledSpan delay={"25ms"}>o</StyledSpan>
              <StyledSpan delay={"50ms"}>n</StyledSpan>
              <StyledSpan delay={"75ms"}>t</StyledSpan>
              <StyledSpan delay={"100ms"}>r</StyledSpan>
              <StyledSpan delay={"125ms"}>a</StyledSpan>
              <StyledSpan delay={"150ms"}>s</StyledSpan>
              <StyledSpan delay={"175ms"}>e</StyledSpan>
              <StyledSpan delay={"200ms"}>ñ</StyledSpan>
              <StyledSpan delay={"225ms"}>a</StyledSpan>
            </StyledLabel>
            <StyledBar className="bar" />
          </StyledControl>

          <StyledBoton
            type="submit"
            color="standard"
            fontcolor="var(--active-blue-button)"
            disabled={false}
            ref={loginRef}
            onFocus={() => loginRef.current.blur()}
            variant="primary"
          >
            Iniciar sesión
          </StyledBoton>
          <ForgotPswdContainer
            onClick={() => navigate("/restablecer-contraseña")}
          >
            Olvide mi contraseña
          </ForgotPswdContainer>
        </StyledForm>
      </StyledContainer>
    </StyledBackground>
  );
};

export default InicioSesion;
