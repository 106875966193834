import React from "react";
import { Modal } from "react-bootstrap";
import {
  StyledBoton,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from "./styles/VentanaMostrarProducto.styles";
import { useRef } from "react";

const VentanaMostrarProducto = ({
  producto,
  mostrarProducto,
  manejarCerrarVentana,
}) => {
  //Obtener referencia del boton
  const cerrarRef = useRef(null);

  return (
    producto && (
      <Modal centered show={mostrarProducto} onHide={manejarCerrarVentana}>
        <StyledModalHeader>
          <h4>Detalles del producto #{producto.id}</h4>
        </StyledModalHeader>
        <StyledModalBody>
          <h5>Datos del producto</h5>
          <p>
            <strong>Nombre:</strong> {producto.NOMBRE}
          </p>
          <p>
            <strong>Cantidad:</strong> {producto.CANTIDAD}
          </p>
          <p>
            <strong>Precio:</strong> {producto.PRECIO}
          </p>
        </StyledModalBody>
        <StyledModalFooter>
          <StyledBoton
            color='blue'
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            onClick={() => {
              manejarCerrarVentana();
            }}
          >
            Cerrar
          </StyledBoton>
        </StyledModalFooter>
      </Modal>
    )
  );
};

export default VentanaMostrarProducto;
