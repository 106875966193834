
import {
  REQUEST_USUARIO_LISTA,
  SUCCESS_USUARIO_LISTA,
  FAIL_USUARIO_LISTA,
  REQUEST_USUARIO_DETALLES,
  SUCCESS_USUARIO_DETALLES,
  FAIL_USUARIO_DETALLES,
  REQUEST_USUARIO_REGISTRAR,
  SUCCESS_USUARIO_REGISTRAR,
  RESET_USUARIO_LISTA,
  FAIL_USUARIO_REGISTRAR,
  REQUEST_USUARIO_ACTUALIZAR,
  SUCCESS_USUARIO_ACTUALIZAR,
  FAIL_USUARIO_ACTUALIZAR,
  REQUEST_USUARIO_BORRAR,
  SUCCESS_USUARIO_BORRAR,
  FAIL_USUARIO_BORRAR,
} from "../constantes/usuarioConstantes";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";
import { actualizarAccessToken } from "./sesionActions";

// Estos si se quedan aqui
export const pedirUsuariosLista = (cancelToken) => async (dispatch) => {
  dispatch({ type: REQUEST_USUARIO_LISTA });

  // setTimeout(async () => {
  try {
    const { data } = await axiosInstance.get("api/usuarios/", {
      cancelToken,
    });

    dispatch({ type: SUCCESS_USUARIO_LISTA, payload: data });
  } catch (error) {
    if (axios.isCancel(error)) console.log(error.message);
    if (error.response && error.response.status === 401) {
      // Redirect user to "/" page if error is due to expired token
      dispatch(actualizarAccessToken(pedirUsuariosLista, cancelToken));
    } else {
      dispatch({ type: FAIL_USUARIO_LISTA, payload: error.message });
    }
  }
  // }, 1500);
};

// Creador de acciones para pedir el producto con el id del backend
export const obtenerUsuarioDetalles = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_USUARIO_DETALLES });

  try {
    const { data } = await axiosInstance.get(`api/usuarios/${id}/`);

    dispatch({ type: SUCCESS_USUARIO_DETALLES, payload: data });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(obtenerUsuarioDetalles, id));
    } else {
      dispatch({ type: FAIL_USUARIO_DETALLES, payload: error.message });
    }
  }
};

export const actualizarUsuario = (usuario) => async (dispatch) => {
  dispatch({ type: REQUEST_USUARIO_ACTUALIZAR });

  try {
    const { data } = await axiosInstance.put(
      `api/modificar-usuario/${usuario.id}/`,
      usuario
    );

    dispatch({ type: SUCCESS_USUARIO_ACTUALIZAR, payload: data });
    dispatch({ type: RESET_USUARIO_LISTA });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(actualizarUsuario, usuario));
    } else {
      dispatch({ type: FAIL_USUARIO_ACTUALIZAR, payload: error.message });
    }
  }
};

// Creador de acciones para registrar un nuevo producto en el backend
export const registrarUsuario = (formData, setDisabledState) => async (dispatch, getState) => {
  dispatch({ type: REQUEST_USUARIO_REGISTRAR });

  try {
    setDisabledState(true);
    const {
      usuarioInfo: { token },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axiosInstance.post(
      "api/crear-cuenta/",
      formData,
      config
    );

    dispatch({ type: SUCCESS_USUARIO_REGISTRAR, payload: data });
    dispatch({ type: RESET_USUARIO_LISTA });
    setDisabledState(false);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(registrarUsuario, formData));
    } else {
      dispatch({ type: FAIL_USUARIO_REGISTRAR, payload: error.message });
    }
    setDisabledState(false);
  }
};

export const borrarUsuario = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_USUARIO_BORRAR });

  try {
    const { data } = await axiosInstance.delete(`api/modificar-usuario/${id}/`);

    dispatch({ type: SUCCESS_USUARIO_BORRAR, payload: data });
    dispatch({ type: RESET_USUARIO_LISTA });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(borrarUsuario, id));
    } else {
      dispatch({ type: FAIL_USUARIO_BORRAR, payload: error.message });
    }
  }
};
