export const REQUEST_AJUSTE_INVENTARIO_LISTA =
  "REQUEST_AJUSTE_INVENTARIO_LISTA";
export const SUCCESS_AJUSTE_INVENTARIO_LISTA =
  "SUCCESS_AJUSTE_INVENTARIO_LISTA";
export const FAIL_AJUSTE_INVENTARIO_LISTA = "FAIL_AJUSTE_INVENTARIO_LISTA";
export const RESET_AJUSTE_INVENTARIO_LISTA = "RESET_AJUSTE_INVENTARIO_LISTA";

export const REQUEST_AJUSTE_INVENTARIO_REGISTRAR =
  "REQUEST_AJUSTE_INVENTARIO_REGISTRAR";
export const SUCCESS_AJUSTE_INVENTARIO_REGISTRAR =
  "SUCCESS_AJUSTE_INVENTARIO_REGISTRAR";
export const FAIL_AJUSTE_INVENTARIO_REGISTRAR =
  "FAIL_AJUSTE_INVENTARIO_REGISTRAR";
export const RESET_AJUSTE_INVENTARIO_REGISTRAR =
  "RESET_AJUSTE_INVENTARIO_REGISTRAR";


  // Pedir lista de AJUSTE_INVENTARIO para el reporte
export const REQUEST_AJUSTE_INVENTARIO_REPORTE_LISTA = "REQUEST_AJUSTE_INVENTARIO_REPORTE_LISTA";
export const SUCCESS_AJUSTE_INVENTARIO_REPORTE_LISTA = "SUCCESS_AJUSTE_INVENTARIO_REPORTE_LISTA";
export const FAIL_AJUSTE_INVENTARIO_REPORTE_LISTA = "FAIL_AJUSTE_INVENTARIO_REPORTE_LISTA";
export const RESET_AJUSTE_INVENTARIO_REPORTE_LISTA = "RESET_AJUSTE_INVENTARIO_REPORTE_LISTA";