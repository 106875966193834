import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { RESET_USUARIO_REGISTRAR } from "../constantes/usuarioConstantes";
import { registrarUsuario } from "../actions/usuarioActions";
import { useForm } from "react-hook-form";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/RegistrarUsuario.styles";
import { useRef } from "react";
import _ from "lodash"; // Import lodash for debouncing
import axios from "axios";
import { BASE_URL } from "../constantes/constantes";

const RegistrarUsuario = () => {
  // Archivos Validos
  const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/svg'];

  // Funcion para disparar acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  //Obtener la referencia del boton
  const registrarRef = useRef(null);

  // Estado deshabilitacion del boton
  const [disabledState, setDisabledState] = useState(false);

  // Obtener el estado registrar usuario del Redux store
  const usuarioRegistrar = useSelector((state) => state.usuarioRegistrar);
  const {
    loading: loadingRegistrar,
    success: successRegistrar,
    error: errorRegistrar,
  } = usuarioRegistrar;

  // useForm para validar formulario
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // // Estado vara validar y registrar username
  // const [nombreUsuario, setNombreUsuario] = useState({
  //   loading: false,
  //   value: "",
  //   error: "",
  // });

  // const setNombreUsuarioValue = (newNombreUsuario) => {
  //   // validate username

  //   setNombreUsuario((prevNU) => ({
  //     ...prevNU,
  //     value: newNombreUsuario,
  //     loading: false,
  //     error: "",
  //   }));
  // };

  // const setNombreUsuarioError = (message) => {
  //   setNombreUsuario((prevNU) => ({
  //     ...prevNU,
  //     loading: false,
  //     error: message,
  //   }));
  // };

  // const setNombreUsuarioLoading = () => {
  //   setNombreUsuario((prevNU) => ({ ...prevNU, error: "", loading: true }));
  // };

  // useEffect(() => {
  //   if (nombreUsuario.value) {
  //     setNombreUsuarioLoading();
  //     validateNombreUsuario(
  //       nombreUsuario.value,
  //       setNombreUsuarioError,
  //       setNombreUsuarioValue
  //     );
  //   }
  // }, [nombreUsuario.value]);

  // useEffect para mostrar las alertas de registro de usuario
  useEffect(() => {
    let toastId;
    if (loadingRegistrar) {
      toast.loading("Registrando usuario");
    }

    if (successRegistrar) {
      toast.dismiss(toastId);
      toast.success("Usuario registrado");
      dispatch({ type: RESET_USUARIO_REGISTRAR });
      navigate("/usuarios");
    }

    if (errorRegistrar) {
      toast.dismiss(toastId);
      toast.error("Error al registrar usuario");
    }
  }, [successRegistrar, errorRegistrar, loadingRegistrar, dispatch, navigate]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  // useEffect para mostrar las alertas de validacion del formulario
  useEffect(() => {
    if (errors.password2) {
      toast.dismiss();
      toast.error(errors.password2.message);
    }

    if (errors.password1) {
      toast.dismiss();
      toast.error(errors.password1.message);
    }

    // if (errors.nombreUsuario) {
    //   toast.dismiss();
    //   toast.error(errors.nombreUsuario.message);
    // }

    if (errors.nombre) {
      toast.dismiss();
      toast.error(errors.nombre.message);
    }

    if (errors.imagen) {
      toast.dismiss();
      toast.error(errors.imagen[0].message)
    }
  }, [errors.nombre, errors.password1, errors.password2, errors.imagen]);

  // Function para registrar usuario
  const manejarRegistrarUsuario = (data) => {
    toast.dismiss();
    if (data.password1 !== data.password2) {
      toast.error("Las contraseñas deben ser iguales", { duration: 4000 });
      return;
    }
    // else if (nombreUsuario.error) {
    //   toast.error(nombreUsuario.error, { duration: 4000 });
    // }
    data.isAdmin = data.isAdmin === "true";
    if (data.role === "GERENTE" && !data.isAdmin) {
      toast.error(
        "Un empleado con puesto de gerente debe tener permisos de administrador"
      );
      return;
    } else if (data.role === "REPARTIDOR" && data.isAdmin) {
      toast.error(
        "Un empleado con puesto de repartidor no debe tener permisos de administrador"
      );
      return;
    }

    const formData = new FormData();

    formData.append("name", data.nombre);
    // formData.append("username", nombreUsuario.value);
    formData.append("username", data.nombreUsuario);
    formData.append("is_admin", data.isAdmin);
    formData.append("password1", data.password1);
    formData.append("password2", data.password2);
    formData.append("role", data.role);
    if (data.imagen[0]) {
      if (!allowedFileTypes.includes(data.imagen[0].type)) {
        toast.dismiss();
        toast.error("Introduce un archivo de imagen válido.");
        return;
      }
      formData.append("IMAGEN", data.imagen[0]);
    }

    // Print formData data
    // for (const entry of formData.entries()) {
    //   console.log(entry[0], entry[1]);
    // }

    // Disparar la accion para registrar usuario
    dispatch(registrarUsuario(formData, setDisabledState));
  };
  return (
    <StyledContainer fluid>
      <h1>Registrar usuario</h1>

      <Form onSubmit={handleSubmit(manejarRegistrarUsuario)}>
        <StyledRow>
          <StyledCol md={6}>
            <StyledFormGroup controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                {...register("nombre", {
                  required: "Por favor, introduce el nombre",
                })}
                type="text"
                autoComplete="off"
              ></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup controlId="nombreUsuario">
              <Form.Label>Nombre de usuario</Form.Label>
              {/* <StyledFormControlNombreUsuario
                // {...register("nombreUsuario", {
                //   required: "Por favor, introduce el nombre de usuario",
                // })}
                value={nombreUsuario.value}
                onChange={(e) => setNombreUsuarioValue(e.target.value)}
                type="text"
                error={nombreUsuario.error}
                autoComplete="off"
                required
              ></StyledFormControlNombreUsuario>
              {nombreUsuario.error && (
                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {nombreUsuario.error}
                </p>
              )}
              {nombreUsuario.loading && (
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Verificando...
                </p>
              )} */}
              <Form.Control
                {...register("nombreUsuario", {
                  required: "Por favor, introduce el nombre de usuario",
                })}
                type="text"
                autoComplete="off"
              ></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup controlId="password1">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                {...register("password1", {
                  required: "Por favor, introduce la contraseña",
                })}
                type="password"
              ></Form.Control>
            </StyledFormGroup>

            <StyledFormGroup controlId="password2">
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control
                {...register("password2", {
                  required: "Por favor, confirma la contraseña",
                })}
                type="password"
              ></Form.Control>
            </StyledFormGroup>
          </StyledCol>
          <StyledCol md={6}>
            <StyledFormGroup controlId="isAdmin">
              <Form.Label>Permisos</Form.Label>
              <Form.Select {...register("isAdmin")}>
                <option value={true}>ADMINISTRADOR</option>
                <option value={false}>NO ES ADMINISTRADOR</option>
              </Form.Select>
            </StyledFormGroup>

            <StyledFormGroup controlId="role">
              <Form.Label>Puesto</Form.Label>
              <Form.Select {...register("role")}>
                <option value="REPARTIDOR">REPARTIDOR</option>
                <option value="CAJERO">CAJERO</option>
                <option value="GERENTE">GERENTE</option>
              </Form.Select>
            </StyledFormGroup>

            <StyledFormGroup controlId="formImage">
              <Form.Label>Imagen</Form.Label>
              <Form.Control {...register("imagen")} type="file" 
                            accept=".png, .jpg, .jpeg, .svg, .gif" 
                            multiple="false"
                           // onChange={(e) => setImagen(e.target.files[0])}
             />
              <StyledCol>
                <StyledBoton
                  type="submit"
                  color="green"
                  disabled={disabledState}
                  ref={registrarRef}
                  onFocus={() => registrarRef.current.blur()}
                >
                  Registrar usuario
                </StyledBoton>
              </StyledCol>
            </StyledFormGroup>
          </StyledCol>
        </StyledRow>
      </Form>
    </StyledContainer>
  );
};

export default RegistrarUsuario;

// Debounced function for username validation
export const validateNombreUsuario = _.debounce(
  async (nombreUsuario, setNombreUsuarioError, setNombreUsuarioValue) => {
    // Replace this with your actual API call
    const errorMessage = await nombreUsuarioExistsApi(nombreUsuario);

    if (errorMessage) {
      setNombreUsuarioError(errorMessage);
    } else {
      setNombreUsuarioValue(nombreUsuario);
    }
  },
  500
); // 500ms debounce time

// API to verify whether nombreUsuario already exists
const nombreUsuarioExistsApi = async (nombreUsuario) => {
  try {
    // const {
    //   usuarioInfo: { token },
    // } = getState();

    const token = JSON.parse(localStorage.getItem("accessToken") || null);

    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${BASE_URL}api/usuarios?nombreUsuario=${nombreUsuario}`,
      config
    );

    if (data.length > 0) return "Nombre de usuario ya existe";
  } catch (error) {
    return error.message;
  }
};
