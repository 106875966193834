import { RESET_CLIENTE_VENTA_LISTA } from "../constantes/clienteConstantes";
import { RESET_PRODUCTO_LISTA } from "../constantes/productoConstantes";
import {
  FAIL_VENTA_ACTUALIZAR,
  FAIL_VENTA_DETALLES,
  FAIL_VENTA_LISTA,
  FAIL_VENTA_REGISTRAR,
  FAIL_VENTA_REPORTE_LISTA,
  REQUEST_VENTA_ACTUALIZAR,
  REQUEST_VENTA_DETALLES,
  REQUEST_VENTA_LISTA,
  REQUEST_VENTA_REGISTRAR,
  REQUEST_VENTA_REPORTE_LISTA,
  RESET_VENTA_LISTA,
  SUCCESS_VENTA_ACTUALIZAR,
  SUCCESS_VENTA_DETALLES,
  SUCCESS_VENTA_LISTA,
  SUCCESS_VENTA_REGISTRAR,
  SUCCESS_VENTA_REPORTE_LISTA,
} from "../constantes/ventaConstantes";
import { actualizarAccessToken } from "./sesionActions";
import * as XLSX from "xlsx";
import { modifyJSON } from "../lib/utilis/venta";
import axiosInstance from "../lib/axiosConfig";
import axios from "axios";

// Creador de acciones para pedir los ventas del backend
export const pedirVentasLista =
  (search = "", cancelToken) =>
  async (dispatch) => {
    dispatch({ type: REQUEST_VENTA_LISTA });

    // setTimeout(async () => {
    // Intentar pedir al backend lista de ventas
    try {
      // Recibir respuesta del backend y guardarla en data
      const { data } = await axiosInstance.get(`api/ventas${search}`, {
        cancelToken,
      });

      dispatch({ type: SUCCESS_VENTA_LISTA, payload: data });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(error.message);
      }
      // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(pedirVentasLista, search, cancelToken));
      } else {
        dispatch({ type: FAIL_VENTA_LISTA, payload: error.message });
      }
    }
    // }, 1500);
  };

// Creador de acciones para pedir los ventas del backend
export const descargarReporteVentas =
  (search = "", setDisabledState) =>
  async (dispatch) => {
    dispatch({ type: REQUEST_VENTA_REPORTE_LISTA });

    // Intentar pedir al backend lista de ventas
    try {
      setDisabledState(true);
      // Recibir respuesta del backend y guardarla en data
      const { data } = await axiosInstance.get(`api/ventas-reporte${search}`);

      dispatch({ type: SUCCESS_VENTA_REPORTE_LISTA, payload: data });

      // Modify the JSON data
      const ventas = modifyJSON(data);

      // Convertir JSON a CSV
      const worksheet = XLSX.utils.json_to_sheet(ventas);
      const csv = XLSX.utils.sheet_to_csv(worksheet);

      // Crear un enlace de descarga para el CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "ventas.csv"); // Nombre del archivo CSV
      document.body.appendChild(link);
      link.click();
      setDisabledState(false);
      
      // Eliminar el enlace una vez descargado
      document.body.removeChild(link);
    } catch (error) {
      // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
      if (error.response && error.response.status === 401) {
        dispatch(actualizarAccessToken(descargarReporteVentas, search));
      } else {
        dispatch({ type: FAIL_VENTA_REPORTE_LISTA, payload: error.message });
      }
      setDisabledState(false);
    }
  };

// Creador de acciones para pedir el venta con el id del backend
export const obtenerVentaDetalles = (id) => async (dispatch) => {
  dispatch({ type: REQUEST_VENTA_DETALLES });

  // Intentar obtener venta del backend
  try {
    // Recibir respuesta del backend y guardarla en data
    const { data } = await axiosInstance.get(`api/ventas/${id}/`);

    dispatch({ type: SUCCESS_VENTA_DETALLES, payload: data });
  } catch (error) {
    // Si el backend responde con error de tipo 401 (no autorizado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(obtenerVentaDetalles, id));
    } else {
      dispatch({ type: FAIL_VENTA_DETALLES, payload: error.message });
    }
  }
};

// Creador de acciones para actualizar venta del backend
export const actualizarVenta = (venta) => async (dispatch) => {
  dispatch({ type: REQUEST_VENTA_ACTUALIZAR });

  // Intentar pedir al backend actualizar la venta
  try {
    // Recibir respuesta del backend y guardarla en data
    const { data } = await axiosInstance.put(
      `api/modificar-venta/${venta.id}/`,

      venta
    );

    dispatch({ type: SUCCESS_VENTA_ACTUALIZAR, payload: data });
    dispatch({ type: RESET_VENTA_LISTA });
    // Si el status de la venta se cambia a realizado, se descuenta producto y por lo tanto hay que actualizar la lista de productos
    dispatch({ type: RESET_PRODUCTO_LISTA });
  } catch (error) {
    // si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(actualizarVenta, venta));
    } else {
      dispatch({ type: FAIL_VENTA_ACTUALIZAR, payload: error.message });
    }
  }
};

// Creador de acciones para registrar un nuevo venta en el backend
export const registrarVenta = (venta) => async (dispatch) => {
  dispatch({ type: REQUEST_VENTA_REGISTRAR });

  // Intentar pedir al backend registrar venta
  try {
    // recibir respuesta del backend y guardarla en data
    const { data } = await axiosInstance.post(`api/crear-venta/`, venta);

    dispatch({ type: SUCCESS_VENTA_REGISTRAR, payload: data });
    dispatch({ type: RESET_VENTA_LISTA });
    // Si la venta de genera con un status de realizado, se descuenta producto del almacen y hay que actualizar la lista de productos
    dispatch({ type: RESET_PRODUCTO_LISTA });
    // por que????????
    // dispatch({ type: RESET_CLIENTE_LISTA });
    // no RESET_CLIENTE_LISTA pero si RESET_CLIENTE_VENTA_LISTA
    dispatch({ type: RESET_CLIENTE_VENTA_LISTA });
  } catch (error) {
    // Si el backend responde con error de tipo 401 (no autenticado) intentar actualizar el token
    if (error.response && error.response.status === 401) {
      dispatch(actualizarAccessToken(registrarVenta, venta));
    } else {
      dispatch({ type: FAIL_VENTA_REGISTRAR, payload: error.message });
    }
  }
};
