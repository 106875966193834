import { useState } from "react";
import { obtenerValoresFiltroAjusteInventario } from "../utilis/ajusteInventario";

export const useFiltrosAjusteInventario = () => {
  // Estado para los filtros de las lista de ventas
  const [filtrosAjusteInventario, setFiltrosAjusteInventario] = useState(
    obtenerValoresFiltroAjusteInventario
  );

  const { buscar, filtrarPor, ordenarPor, fechaInicio, fechaFinal } =
    filtrosAjusteInventario;

  // Funcion para modificar el valor con keys dinamicas
  const modificarFiltro = (e) =>
    setFiltrosAjusteInventario((prevFiltrosVentas) => ({
      ...prevFiltrosVentas,
      [e.target.name]: e.target.value,
    }));

  // Generar el url
  const url = `/ajuste-inventarios?filtrarpor=${filtrarPor}&buscar=${buscar}&ordenarpor=${ordenarPor}&fechainicio=${fechaInicio}&fechafinal=${fechaFinal}`;

  return {
    buscar,
    filtrarPor,
    ordenarPor,
    fechaInicio,
    fechaFinal,
    url,
    modificarFiltro,
  };
};
