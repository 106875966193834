import React from "react";
// import { useMediaQuery } from "react-responsive";
import ImagenObjeto from "../general/ImagenObjecto";
import { StyledOptionsContainer, StyledTD, TableStyled } from "./styles/TablaUsuarios.styles";
import { BASE_URL } from "../../constantes/constantes";
import UseScreenSize from "../general/UseScreenSize";
import {
  StyledGenericButton,
  StyledDeleteButton,
} from "./styles/TablaUsuarios.styles";
import { useRef } from "react";

const TablaUsuarios = ({
  usuarios,
  manejarUsuarioDetalles,
  manejarBorrarUsuario,
}) => {
  // const isSmallViewport = useMediaQuery({ maxWidth: 768 });
  // const shouldShow = !isSmallViewport;
  const { alto, ancho } = UseScreenSize();

  // Obtener referencias de los botones
  const editarRef = useRef(null);
  const deleteRef = useRef(null);

  return (
    <TableStyled striped hover>
      <thead>
        <tr>
          <th>ID</th>
          {ancho > 720 ? <th>IMAGEN</th> : null}
          {ancho > 870 ? <th>USUARIO</th>:null}
          <th>NOMBRE</th>
          {ancho > 530 ? <th>PERMISOS</th> : null}

          <th>ROL</th>
          <th>EDITAR / BORRAR</th>
        </tr>
      </thead>
      <tbody>
        {usuarios
          .filter((u) => u.id !== JSON.parse(localStorage.getItem("usuarioId")))
          .map((u) => (
            <tr key={u.id}>
              <td>{u.id}</td>
              {ancho > 720 ? (
                <td>
                  <ImagenObjeto
                    src={`${BASE_URL}${u.empleado.IMAGEN}`}
                    alt={u.name}
                  />
                </td>
              ) : null}

              {ancho > 870 ? (
                <td>
                  <StyledTD lines={2}>{u.username}</StyledTD>
                </td>
              ) : null}

              <td>
                <StyledTD lines={2}>{u.name}</StyledTD>
              </td>
              {ancho > 530 ? (
                <td style={{ color: "white" }}>
                  {ancho > 790 ? 
                      u.is_admin
                      ? "ADMINISTRADOR"
                      : "NO ES ADMINISTRADOR"
                    : u.is_admin 
                    ? 'ADMIN'
                    : 'NO ES ADMIN'
                  }
                  </td>
              ) : null}

              <td>
                  {u.role}
              </td>
              <td>
                <StyledOptionsContainer>
                  <StyledGenericButton 
                    color='standard'
                    disabled={false}
                    ref={editarRef}
                    onFocus={() => editarRef.current.blur()}
                    onClick={() => manejarUsuarioDetalles(u.id)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </StyledGenericButton>

                  <StyledDeleteButton
                    color='red'
                    disabled={false}
                    ref={deleteRef}
                    onFocus={() => deleteRef.current.blur()}
                    variant="danger"
                    onClick={(e) => manejarBorrarUsuario(e, u.id)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </StyledDeleteButton>
                </StyledOptionsContainer>
              </td>
            </tr>
          ))}
      </tbody>
    </TableStyled>
  );
};

const truncateTexto = (texto, maxLength) => {
  if (texto.length > maxLength) {
    return texto.slice(0, maxLength) + "...";
  }
  return texto;
};

export default TablaUsuarios;
