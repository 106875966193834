import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { actualizarVenta, obtenerVentaDetalles } from "../actions/ventaActions";

import {
  RESET_VENTA_ACTUALIZAR,
  RESET_VENTA_DETALLES,
} from "../constantes/ventaConstantes";
import Mensaje from "../componentes/general/Mensaje";
import Loader from "../componentes/general/Loader";
import VentanaMostrarVentaActualizar from "../componentes/VentaDetalles/VentanaMostrarVentaActualizar";
import { toast } from "react-hot-toast";
import {
  StyledBoton,
  StyledCol,
  StyledContainer,
  StyledFormGroup,
  StyledRow,
} from "./styles/VentaDetalles.styles";
import { Form } from "react-bootstrap";
import { useRef } from "react";

const VentaDetalles = () => {
  // Obtener el id de la venta
  const params = useParams();
  const ventaId = params.id;

  //Obtener referencias de los botones
  const regresarRef = useRef(null);
  const actualizarRef = useRef(null);

  // Funcion para disparar las acciones
  const dispatch = useDispatch();

  // Funcion para navegar en la pagina
  const navigate = useNavigate();

  // Obtener el estado desde el Redux store
  const ventaDetalles = useSelector((state) => state.ventaDetalles);
  const { loading, venta, error } = ventaDetalles;

  // Obtener el estado desde el Redux store
  const ventaActualizar = useSelector((state) => state.ventaActualizar);
  const {
    loading: actualizarLoading,
    reporte: actualizarReporte,
    error: actualizarError,
  } = ventaActualizar;

  const [status, setStatus] = useState("");
  const [mostrarReporteActualizar, setMostrarReporteActualizar] =
    useState(false);

  // useEffect para cargar datos de venta
  useEffect(() => {
    // Si no hay venta o la venta no es la que seleccione, disparar la accion de
    // obtener venta
    if (!venta || venta.id !== Number(ventaId)) {
      dispatch(obtenerVentaDetalles(ventaId));
    } else {
      setStatus(venta.STATUS);
    }
  }, [venta, dispatch, ventaId]);

  // useEffect para mostrar reporte de actualizacion
  useEffect(() => {
    if (actualizarLoading) {
      toast.remove();
      toast.loading("Actualizando venta");
    }

    if (actualizarError) {
      toast.dismiss();
      toast.error(
        "Error al actualizar venta. Verifica que exita producto suficiente para realizar esta operación"
      );
    }
    // Si la actualizacion fue correcta, reset productoActualizar y redireccionar a la pagina de productos
    if (actualizarReporte) {
      toast.remove();
      setMostrarReporteActualizar(true);
    }
  }, [actualizarReporte, actualizarError, actualizarLoading]);

  const manejarActualizarVenta = (e) => {
    e.preventDefault();
    // Disparar la accion de actualizar producto
    dispatch(
      actualizarVenta({
        // El id es para el endpoint, no como informacion de actualizacion
        id: ventaId,
        STATUS: status,
      })
    );
  };

  const manejarCerrarVentana = () => {
    setMostrarReporteActualizar(false);
    // Reset venta actualizar para no mostrar VentanaMostrarVentaActualizar cada vez que se entra a venta detalles
    dispatch({ type: RESET_VENTA_ACTUALIZAR });
    navigate("/ventas");
  };

  const manejarRegresar = () => {
    // Redireccionar a la pagina de ventas
    dispatch({ type: RESET_VENTA_DETALLES });
    navigate("/ventas");
  };

  if (loading) return <Loader />;

  if (error)
    return (
      <StyledContainer fluid>
        <StyledRow>
          <StyledCol>
            <Mensaje variant="danger">
              Hubo un problema al cargar la informacion de la venta
            </Mensaje>
          </StyledCol>
        </StyledRow>
      </StyledContainer>
    );

  return (
    venta && (
      <>
        <StyledContainer fluid>
          <StyledRow>
            <StyledCol md={6}>
              <h1>Venta #{venta.FOLIO}</h1>
              <div>
                <StyledBoton
                  variant="primary"
                  color="green"
                  disabled={false}
                  ref={regresarRef}
                  onFocus={() => regresarRef.current.blur()}
                  onClick={manejarRegresar}>
                  Regresar
                </StyledBoton>
              </div>
            </StyledCol>
          </StyledRow>
          <Form onSubmit={manejarActualizarVenta}>
            <StyledRow>
              <StyledCol>
                <StyledFormGroup controlId="status">
                  <Form.Label style={{ color: "white" }}>STATUS</Form.Label>
                  <Form.Control
                    as="select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}>
                    <option value="PENDIENTE">Pendiente</option>
                    <option value="REALIZADO">Realizado</option>
                    {venta.TIPO_VENTA === "MOSTRADOR" && (
                      <option value="CANCELADO">Cancelado</option>
                    )}
                  </Form.Control>
                </StyledFormGroup>
                <div>
                  <StyledBoton
                    type="submit"
                    color="green"
                    disabled={false}
                    ref={actualizarRef}
                    onFocus={() => actualizarRef.current.blur()}>
                    Actualizar venta
                  </StyledBoton>
                </div>
              </StyledCol>
            </StyledRow>
          </Form>
        </StyledContainer>

        {mostrarReporteActualizar && (
          <VentanaMostrarVentaActualizar
            reporteActualizar={actualizarReporte}
            mostrarReporte={mostrarReporteActualizar}
            manejarCerrarVentana={manejarCerrarVentana}
          />
        )}
      </>
    )
  );
};

export default VentaDetalles;
