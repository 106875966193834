import { StyledTexto } from "./styles/Separador.styles";

const Separador = ({ doble }) => {
  return doble ? (
    <StyledTexto
      tamaño={"17px"}
      alineacion={"center"}
      topSpan={"-15px"}
      height={"25px"}
      heightSpan={"4px"}
      top={"-5px"}
      bottom={"0px"}
    >
      {"_".repeat(40)}
      <span>{"_".repeat(40)}</span>
    </StyledTexto>
  ) : (
    <StyledTexto tamaño={"17px"} alineacion={"center"} top={"-5px"} bottom={"0px"}>
      {".".repeat(80)}
    </StyledTexto>
  );
};

export default Separador;
