import React from "react";
import { Form, Modal } from "react-bootstrap";
import {
  StyledBoton,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
} from "./styles/VentanaFormularioRuta.styles";
import { useRef } from "react";

const DAY_WEEK = {
  LUNES: "Lunes",
  MARTES: "Martes",
  MIERCOLES: "Miércoles",
  JUEVES: "Jueves",
  VIERNES: "Viernes",
  SABADO: "Sábado",
  DOMINGO: "Domíngo",
};

const VentanaFormularioRuta = ({
  rutas,
  rutasRedux,
  modificarDayIds,
  modificarRuta,
  mostrarRutas,
  manejarCerrarVentana,
}) => {

  //Obtener referencia del boton
  const cerrarRef = useRef(null);

  return (
    rutasRedux && (
      <Modal centered show={mostrarRutas} onHide={manejarCerrarVentana}>
        <StyledModalHeader>
          <h4>Rutas del Cliente </h4>
        </StyledModalHeader>
        <StyledModalBody>
          <Form>
            <Form.Group controlId="ruta">
              <Form.Label>Ruta:</Form.Label>
              <Form.Control
                as="select"
                value={rutas.nombre}
                onChange={(e) => {
                  modificarRuta(e.target.value);
                }}
              >
                <option value="">Selecciona el nombre de la ruta</option>
                {rutasRedux.map((r) => (
                  <option value={r.NOMBRE} key={r.NOMBRE}>
                    {r.NOMBRE}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {rutas.rutaDays && (
              <Form.Group>
                <Form.Label className="mt-2">Días de la semana:</Form.Label>
                {Object.keys(DAY_WEEK).map((day, index) => (
                  <Form.Check
                    key={day}
                    id={`checkbox-${index}`} // existing id
                    type="checkbox"
                    label={DAY_WEEK[day]}
                    value={day}
                    checked={rutas.selectedIds.includes(rutas.rutaDays[day])}
                    onChange={(e) => {
                      modificarDayIds(
                        rutas.rutaDays[e.target.value],
                        e.target.checked
                      );
                    }}
                  />
                ))}
              </Form.Group>
            )}
          </Form>
        </StyledModalBody>
        <StyledModalFooter>
          <StyledBoton
            color='blue'
            disabled={false}
            ref={cerrarRef}
            onFocus={() => cerrarRef.current.blur()}
            variant="secondary"
            onClick={() => {
              manejarCerrarVentana();
            }}
          >
            Cerrar
          </StyledBoton>
        </StyledModalFooter>
      </Modal>
    )
  );
};

export default VentanaFormularioRuta;
